import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import IconWrapper from "./IconWrapper";
import RoundButton from "./RoundButton";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "./icons/InfoIcon";
import AudienceIcon from "./icons/AudienceIcon";
import InternationalIcon from "./icons/InternationalIcon";
import NationalIcon from "./icons/NationalIcon";
import StarIcon from "./icons/StarIcon";

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: "capitalize",
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}));

const IconsInfoDialog = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <RoundButton variant="text" size="medium" color="primary" className={classes.button} onClick={handleClickOpen}>
        <InfoIcon className={classes.buttonIcon} fill={theme.palette.secondary.main} />
        {t("Icon")} {t("info")}
      </RoundButton>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth aria-labelledby="icon-info-dialog-title">
        <DialogTitle id="icon-info-dialog-title" style={{ padding: 24, fontWeight: 500 }}>
          <Box textAlign="center">
            {t("Icon")} {t("info")}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mb={4}>
            <Box mb={2}>
              <Typography variant="subtitle1">
                <strong>{t("Strategic Score")}</strong>
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {Array.from(Array(11), (_, index) => index)
                .reverse()
                .map(score => (
                  <Box key={score} mr={3}>
                    <StarIcon number={score || 0} fill={theme.palette.secondary.dark} width={24} height={24} />
                  </Box>
                ))}
            </Grid>
          </Box>
          <Box mb={4}>
            <Box mb={2}>
              <Typography variant="subtitle1">
                <strong>{t("Audience")}</strong>
              </Typography>
            </Box>
            <Box mr={1}>
              <IconWrapper>
                <AudienceIcon fill={theme.palette.secondary.main} width={24} height={24} />
              </IconWrapper>
            </Box>
          </Box>
          <Box mb={4}>
            <Box mb={2}>
              <Typography variant="subtitle1">
                <strong>
                  {t("International")} / {t("National")}
                </strong>
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={3}>
                <Box display="flex" alignItems="center">
                  <Box mr={1}>
                    <IconWrapper>
                      <InternationalIcon fill={theme.palette.secondary.main} />
                    </IconWrapper>
                  </Box>
                  <Typography variant="subtitle2" component="p">
                    {t("International")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Box display="flex" alignItems="center">
                  <Box mr={1}>
                    <IconWrapper>
                      <NationalIcon />
                    </IconWrapper>
                  </Box>
                  <Typography variant="subtitle2" component="p">
                    {t("National")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: 24 }}>
          <RoundButton
            variant="contained"
            size="large"
            onClick={handleClose}
            color="secondary"
            style={{ color: "white" }}
          >
            {t("Close")}
          </RoundButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

IconsInfoDialog.propTypes = {};

export default IconsInfoDialog;
