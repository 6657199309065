const defaultTheme = {
  palette: {
    text: { light: "#666666", primary: "#07203B" },
    primary: {
      main: "#203934",
    },
    secondary: {
      light: "#91CCC0",
      main: "#6dbbab",
      dark: "#4d8d80",
    },
    background: {
      paper: "#fff",
      default: "#fff",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h6: {
      fontSize: "1rem",
      fontWeight: 700,
    },
    // subtitle1: {
    //   fontWeight: 700,
    // },
    subtitle2: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    body1: {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: "#07203B",
    },
    body2: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "1.33",
      color: "#666666",
    },
  },
};

export default defaultTheme;
