import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./locales/en";
import da from "./locales/da";

const resources = {
  en,
  da,
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "da",
    fallbackLng: "da",

    debug: process.env.NODE_ENV.includes("dev"),
    saveMissing: true,
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
