import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  root: {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "7",
    "&::before": {
      content: "''",
      position: "absolute",
      top: "0",
      right: "0",
      left: "0",
      bottom: "0",
      zIndex: "8",
      backgroundColor: "rgba(255, 255, 255, 0.6)",
    },
    "& > *": {
      zIndex: "9",
    },
  },
};

const FormLoader = ({ isLoading = false, color = "primary", size = 80, thickness = 4.6, classes = {} }) =>
  isLoading && (
    <div className={classes.root}>
      <CircularProgress color={color} size={size} thickness={thickness} />
    </div>
  );

FormLoader.propTypes = {
  isLoading: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.number,
  thickness: PropTypes.number,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
};

export default withStyles(styles)(FormLoader);
