import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AlertDialog from "./AlertDialog";
import ButtonUI from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

const DeleteAlertButton = ({
  buttonType = "default",
  children = "Delete",
  alertText,
  alertParams = {},
  variant = "contained",
  disabled,
  onClick,
}) => {
  const { t } = useTranslation();
  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState(false);
  const Button = buttonType === "icon" ? IconButton : ButtonUI;

  return (
    <>
      <Button
        aria-label="Delete button"
        variant={variant}
        disabled={disabled}
        onClick={event => {
          event.stopPropagation();
          setIsOpenAlertDialog(true);
        }}
      >
        {children}
      </Button>
      <AlertDialog
        maxWidth="xs"
        confirmButtonText={t("Delete")}
        {...alertParams}
        isOpen={isOpenAlertDialog}
        title={alertText}
        onClose={event => {
          event.stopPropagation();
          setIsOpenAlertDialog(false);
        }}
        onConfirm={event => {
          event.stopPropagation();
          onClick(event);
          setIsOpenAlertDialog(false);
        }}
      />
    </>
  );
};

DeleteAlertButton.propTypes = {
  buttonType: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  alertText: PropTypes.string.isRequired,
  alertParams: PropTypes.object,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default DeleteAlertButton;
