import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import addWeeks from "date-fns/addWeeks";
import addMonths from "date-fns/addMonths";
import isSameDay from "date-fns/isSameDay";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import {
  ageGroupFields,
  audienceFields,
  eventCategoryFields,
  eventInfluenceFields,
  eventTypeFields,
  locationsFields,
  tagsFields,
} from "../constants";
import { isEmptyObject } from "../helpers";
import CheckboxesFilterMenu from "../Components/CheckboxesFilterMenu";
import ScoreFilterMenu from "../Components/ScoreFilterMenu";
import DateFilterMenu from "../Components/DateFilterMenu";
import MoreFilterMenu from "../Components/MoreFilterMenu";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "8px 16px 0 24px",
    borderTop: "1px solid #e5e5e5",
    borderBottom: "1px solid #e5e5e5",
    marginRight: theme.spacing(-3),
    marginLeft: theme.spacing(-3),
    "&>div": {
      marginRight: 8,
      marginBottom: 8,
    },
  },
}));

const useEventsFilters = eventsDefault => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [events, setEvents] = useState(eventsDefault);
  const [filters, setFilters] = useState({
    dates: {
      startDate: addWeeks(new Date(), -1),
      endDate: addWeeks(addMonths(new Date(), 3), -1),
    },
  });

  const saveFilters = type => (value = {}) =>
    setFilters(filters =>
      isEmptyObject(filters[type]) && isEmptyObject(value) ? filters : { ...filters, [type]: value },
    );

  useEffect(() => {
    const filtersArray = Object.entries(filters);

    if (filtersArray.every(([, items]) => Object.keys(items).length === 0)) {
      setEvents(eventsDefault);
    } else {
      setEvents(
        eventsDefault
          .filter(event => {
            const startDates = [event.startDate].concat(event.alternativeStartDates).map(date => new Date(date));
            const endDates = [event.endDate].concat(event.alternativeEndDates).map(date => new Date(date));
            const startDate = new Date(filters.dates?.startDate);
            const endDate = new Date(filters.dates?.endDate);

            return (
              !filters.dates?.startDate ||
              !filters.dates?.endDate ||
              [startDate.toString(), endDate.toString()].includes("Invalid Date") ||
              startDates.find(
                date =>
                  isSameDay(date, startDate) ||
                  isSameDay(date, endDate) ||
                  (isAfter(date, startDate) && isBefore(date, endDate)),
              ) ||
              endDates.find(
                date =>
                  isSameDay(date, startDate) ||
                  isSameDay(date, endDate) ||
                  (isBefore(date, endDate) && isAfter(date, startDate)),
              )
            );
          })
          .filter(event =>
            filtersArray
              .reduce((acc, [key, value]) => {
                if (!Object.keys(value || {}).length > 0) return acc;

                if (key.includes("others")) {
                  const keys = Object.keys(value);

                  if (keys.includes("participants")) {
                    return [...acc, ["participants", value.participants]];
                  } else if (keys.includes("price")) {
                    return [...acc, ["price", value.price]];
                  } else {
                    return [...acc, [key, value]];
                  }
                }

                return [...acc, [key, value]];
              }, [])
              .every(([key, checked]) => {
                if (key === "dates") return true;

                if (key === "others") {
                  return Object.entries(checked).every(([keyOthers, valueOthers]) => {
                    if (checked.nationality && ["nationality", "international"].includes(keyOthers)) {
                      return (
                        (checked.nationality && checked.international && event["international"] !== null) ||
                        !event["international"]
                      );
                    }

                    if (keyOthers === "recurring" || keyOthers === "subsidiary") {
                      return (valueOthers.yes && event[keyOthers]) || (valueOthers.no && !event[keyOthers]);
                    }

                    if (keyOthers === "primaryFocus") {
                      const value = event[keyOthers].toLowerCase();
                      return (valueOthers.btc && value === "btc") || (valueOthers.btb && value !== "btc");
                    }

                    return event[keyOthers];
                  });
                }

                return checked[event[key]];
              }),
          ),
      );
    }
  }, [filters, eventsDefault]);

  const DateFilterMenuComponent = useCallback(
    () => (
      <DateFilterMenu
        buttonText={t("Dates")}
        activeFields={filters.dates || {}}
        onSave={([startDate, endDate]) =>
          saveFilters("dates")({
            startDate,
            endDate,
          })
        }
      />
    ),
    [t, filters.dates],
  );

  const Component = useCallback(
    ({ isShowDateFilter = true }) => (
      <div className={classes.root}>
        <CheckboxesFilterMenu
          multiple
          ariaId="event-type"
          buttonText={t("Event Type")}
          fields={eventTypeFields}
          activeFields={filters.type || {}}
          onSave={saveFilters("type")}
        />
        <CheckboxesFilterMenu
          multiple
          ariaId="category"
          buttonText={t("Category")}
          fields={eventCategoryFields}
          activeFields={filters.category || {}}
          onSave={saveFilters("category")}
        />
        <CheckboxesFilterMenu
          multiple
          ariaId="location-filters"
          buttonText={t("Location")}
          fields={locationsFields}
          activeFields={filters.location || {}}
          onSave={saveFilters("location")}
        />
        <ScoreFilterMenu
          buttonText={t("Strategic Score")}
          activeFields={filters.score || {}}
          onSave={([start, end]) => {
            if (start === 0 && end === 10) return saveFilters("score")({});
            const scores = Array.from(Array(end), (_, index) => [index + 1, true]).slice(start > 0 ? start - 1 : start);
            if (start === 0) scores.push([null, true]);
            return saveFilters("score")(Object.fromEntries(scores));
          }}
        />
        <CheckboxesFilterMenu
          multiple
          ariaId="tags-filters"
          buttonText={t("Brand themes")}
          fields={tagsFields}
          activeFields={filters.tags || {}}
          onSave={saveFilters("tags")}
        />
        <CheckboxesFilterMenu
          multiple
          ariaId="audience-filters"
          buttonText={t("Audience")}
          fields={audienceFields}
          activeFields={filters.audience || {}}
          onSave={saveFilters("audience")}
        />
        <CheckboxesFilterMenu
          multiple
          ariaId="age-group-filters"
          buttonText={t("Target Group")}
          fields={ageGroupFields}
          activeFields={filters.ageGroup || {}}
          onSave={saveFilters("ageGroup")}
        />
        <CheckboxesFilterMenu
          multiple
          ariaId="influence-filters"
          buttonText={`${t("Event")} ${t("influence")}`}
          fields={eventInfluenceFields}
          activeFields={filters.others || {}}
          onSave={saveFilters("others")}
          descr={t("influence-filter-descr")}
        >
          <Box maxWidth={295}>
            <Typography>{t("Bevæger")}:</Typography>
            <Typography>{t("Bevæger_descr1")}</Typography>
            <Typography>{t("Bevæger_descr2")}</Typography>
            <Typography>{t("Bevæger_descr3")}</Typography>
            <Typography>{t("Bevæger_descr4")}</Typography>
            <Typography paragraph>{t("Bevæger_descr5")}</Typography>
            <Typography>{t("Bøvler")}:</Typography>
            <Typography paragraph>{t("Bøvler_descr1")}</Typography>
            <Typography>{t("Begejstrer")}:</Typography>
            <Typography>{t("Begejstrer_descr")}</Typography>
          </Box>
        </CheckboxesFilterMenu>
        <MoreFilterMenu
          buttonText={t("More Filters")}
          activeFields={filters.others || {}}
          onSave={saveFilters("others")}
        />
        {isShowDateFilter && <DateFilterMenuComponent />}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classes.root, t, filters.dates, filters.score],
  );

  return { events, filters, Component, DateFilterMenuComponent };
};

export default useEventsFilters;
