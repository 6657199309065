import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { getFirstDate } from "../helpers/utils";

const useStyles = makeStyles({
  formControl: {
    minWidth: 220,
  },
});

const useEventsSort = (eventsDefault, defaultSortType = "") => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [sortType, setSortType] = useState(defaultSortType);
  const [events, setEvents] = useState(eventsDefault);

  const handleChange = event => setSortType(event.target.value);

  useEffect(() => {
    switch (sortType) {
      case "startDate":
        {
          const newEvents = [...eventsDefault];
          setEvents([
            ...newEvents.filter(({ startDate }) => !startDate),
            ...newEvents
              .filter(({ startDate }) => startDate)
              .sort(
                (
                  { startDate: a, alternativeStartDates: arrA = [] },
                  { startDate: b, alternativeStartDates: arrB = [] },
                ) => getFirstDate([a, ...arrA]) - getFirstDate([b, ...arrB]),
              ),
          ]);
        }
        break;
      case "name":
        setEvents([...eventsDefault].sort(({ name: nameA = "" }, { name: nameB = "" }) => nameA.localeCompare(nameB)));
        break;
      case "":
      default:
        setEvents(eventsDefault);
        break;
    }
  }, [sortType, eventsDefault]);

  const Component = () => (
    <FormControl className={classes.formControl}>
      <InputLabel id="sort-event-select-label">{t("Sort By")}</InputLabel>
      <Select labelId="sort-event-select-label" id="sort-event-select" value={sortType} onChange={handleChange}>
        <MenuItem value="">{t("None")}</MenuItem>
        <MenuItem value="name">{t("Name")}</MenuItem>
        <MenuItem value="startDate">{t("Starting date")}</MenuItem>
      </Select>
    </FormControl>
  );

  return { events, sortType, Component };
};

export default useEventsSort;
