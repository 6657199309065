/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

const FestivalIcon = ({ width = 20, height = 20, fill = "#203934", className = "" }) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 20 20" className={className}>
    <path
      d="M5.84687 7.91759C5.7805 7.70474 5.5991 7.54795 5.37883 7.51306L4.06334 7.3065L3.48772 6.10605C3.39159 5.90465 3.1896 5.7759 2.96644 5.77305C2.74441 5.74559 2.53844 5.89433 2.43715 6.09347L1.83407 7.2808L0.514599 7.45705C0.293118 7.48677 0.108276 7.63896 0.0367539 7.85009C-0.0347685 8.06122 0.0201526 8.29469 0.17808 8.45262L1.12049 9.39331L0.880726 10.7036C0.840688 10.9228 0.928226 11.1459 1.10732 11.2793C1.28642 11.4126 1.5256 11.432 1.72357 11.3307L2.90917 10.7242L4.08045 11.3577C4.279 11.464 4.51661 11.4486 4.69786 11.3199C4.87981 11.1911 4.97309 10.9697 4.93762 10.75L4.72763 9.43448L5.69179 8.51668C5.85316 8.36278 5.91382 8.13044 5.84687 7.91759Z"
      fill={fill}
    />
    <path
      d="M11.6578 0.20739C11.5468 0.07579 11.3831 0.000244141 11.2109 0.000244141H8.78911C8.61689 0.000244141 8.45322 0.07579 8.34224 0.20739C8.23068 0.338989 8.1826 0.512385 8.21123 0.682305L9.38309 7.75244C9.42992 8.03509 9.71332 8.24224 9.99999 8.24224C10.2867 8.24224 10.5701 8.03509 10.617 7.75244L11.7888 0.682305C11.8174 0.512346 11.7693 0.338989 11.6578 0.20739Z"
      fill={fill}
    />
    <path
      d="M5.84343 5.04015L4.35349 0.405337C4.29799 0.23366 4.16639 0.0974899 3.99701 0.0356939C3.82705 -0.0272349 3.63823 -0.00551645 3.48546 0.0894822L1.49767 1.37075C1.3449 1.4663 1.2442 1.62712 1.22588 1.80618C1.20701 1.98586 1.27166 2.16379 1.40154 2.2891L4.87931 5.64268C5.07005 5.82659 5.36539 5.86225 5.59683 5.71764C5.82457 5.57519 5.92644 5.29593 5.84343 5.04015Z"
      fill={fill}
    />
    <path
      d="M19.8626 3.74534L18.356 1.9504C18.2456 1.81822 18.0825 1.74154 17.9102 1.74099C17.7323 1.68834 17.5738 1.81482 17.4622 1.94583L12.8292 7.36275C12.6427 7.58075 12.6416 7.90118 12.8258 8.12036C13.0114 8.34215 13.3294 8.39231 13.572 8.24852L19.7116 4.62654C19.8604 4.539 19.9622 4.3908 19.9914 4.22084C20.0205 4.05088 19.9736 3.87694 19.8626 3.74534Z"
      fill={fill}
    />
    <path
      d="M14.7265 0.000244141C13.7573 0.000244141 12.9688 0.827811 12.9688 1.79709C12.9688 2.76638 13.7573 3.55484 14.7265 3.55484C15.6958 3.55484 16.4843 2.76634 16.4843 1.79709C16.4843 0.827811 15.6958 0.000244141 14.7265 0.000244141Z"
      fill={fill}
    />
    <path
      d="M19.4141 8.24242C19.7377 8.24242 20 7.98009 20 7.65649C20 7.33289 19.7377 7.07056 19.4141 7.07056C19.0905 7.07056 18.8281 7.33289 18.8281 7.65649C18.8281 7.98009 19.0905 8.24242 19.4141 8.24242Z"
      fill={fill}
    />
    <path
      d="M7.61718 7.07054C7.94078 7.07054 8.20311 6.80821 8.20311 6.48461C8.20311 6.16101 7.94078 5.89868 7.61718 5.89868C7.29358 5.89868 7.03125 6.16101 7.03125 6.48461C7.03125 6.80821 7.29358 7.07054 7.61718 7.07054Z"
      fill={fill}
    />
    <path
      d="M12.3828 5.89866C12.7064 5.89866 12.9687 5.63633 12.9687 5.31274C12.9687 4.98914 12.7064 4.72681 12.3828 4.72681C12.0592 4.72681 11.7969 4.98914 11.7969 5.31274C11.7969 5.63633 12.0592 5.89866 12.3828 5.89866Z"
      fill={fill}
    />
    <path
      d="M0.585929 4.72703C0.909529 4.72703 1.17186 4.4647 1.17186 4.1411C1.17186 3.81751 0.909529 3.55518 0.585929 3.55518C0.262329 3.55518 0 3.81751 0 4.1411C0 4.4647 0.262329 4.72703 0.585929 4.72703Z"
      fill={fill}
    />
    <path
      d="M18.2421 9.4138H17.6562V8.82787C17.6562 8.50401 17.3942 8.24194 17.0703 8.24194C16.7464 8.24194 16.4844 8.50401 16.4844 8.82787V9.4138H15.8984C15.5746 9.4138 15.3125 9.67587 15.3125 9.99973C15.3125 10.3236 15.5746 10.5857 15.8984 10.5857H16.4844V11.1716C16.4844 11.4955 16.7464 11.7575 17.0703 11.7575C17.3942 11.7575 17.6562 11.4955 17.6562 11.1716V10.5857H18.2421C18.566 10.5857 18.8281 10.3236 18.8281 9.99973C18.8281 9.67587 18.566 9.4138 18.2421 9.4138Z"
      fill={fill}
    />
    <path d="M7.65723 14.901L8.02687 15.8867L12.5258 14.413L13.001 13.1458L7.65723 14.901Z" fill={fill} />
    <path d="M6.87598 12.8175L7.24566 13.8034L13.5302 11.7344L14.0055 10.467L6.87598 12.8175Z" fill={fill} />
    <path
      d="M6.44542 9.41382C6.25316 9.41386 6.07347 9.50827 5.96363 9.6662C5.85433 9.82412 5.82918 10.0255 5.89668 10.2058L6.46436 11.7196L13.4596 9.41386H6.44542V9.41382Z"
      fill={fill}
    />
    <path
      d="M8.43848 16.984L9.45135 19.6197C9.53717 19.8481 9.75576 19.9997 10.0001 19.9997C10.2444 19.9997 10.5021 19.8481 10.5879 19.6197L11.9967 15.824L8.43848 16.984Z"
      fill={fill}
    />
  </svg>
);

FestivalIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default FestivalIcon;
