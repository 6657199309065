/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowLeftIcon from "@material-ui/icons/ChevronLeft";
import ArrowRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles({
  root: {
    position: "relative",
    paddingRight: 26,
    paddingLeft: 26,
    width: "100%",
    height: 24,
    borderRadius: 4,
    overflow: "hidden",
    backgroundColor: "#f2f2f2",
  },
  bar: {
    height: 24,
    borderRadius: 4,
    border: "1px solid #ccc",
    backgroundColor: "#e5e5e5",
    cursor: "grab",
  },
  iconButton: {
    position: "absolute",
    top: 0,
    width: 26,
    height: 24,
  },
  iconButtonLeft: {
    left: 0,
  },
  iconButtonRight: {
    right: 0,
  },
  iconButtonIcon: {
    position: "absolute",
    fill: "#b2b2b2",
  },
  iconButtonIconLeft: {
    left: 1,
  },
  iconButtonIconRight: {
    right: 1,
  },
});

const ScrollBar = ({ customScrollElRef, scrollLeft, scrollRight, onMove, disabled }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={disabled ? { pointerEvents: "none" } : {}}>
      <div ref={customScrollElRef}>
        <IconButton className={`${classes.iconButton} ${classes.iconButtonLeft}`} onClick={scrollLeft}>
          <ArrowLeftIcon className={`${classes.iconButtonIcon} ${classes.iconButtonIconLeft}`} />
        </IconButton>

        <IconButton className={`${classes.iconButton} ${classes.iconButtonRight}`} onClick={scrollRight}>
          <ArrowRightIcon className={`${classes.iconButtonIcon} ${classes.iconButtonIconRight}`} />
        </IconButton>

        <div draggable className={`scroll ${classes.bar}`} onDragStart={() => false} onMouseDown={onMove} />
      </div>
    </div>
  );
};

ScrollBar.propTypes = {
  customScrollElRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  scrollLeft: PropTypes.func.isRequired,
  scrollRight: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ScrollBar;
