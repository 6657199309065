import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { strategicScoresFields } from "../constants";
import { apiPutEvent } from "../services";
import FormLoader from "./FormLoader";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconWrapper from "./IconWrapper";
import StarIcon from "./icons/StarIcon";

const StrategicScoreForm = ({ eventId, strategicScores = [], onSubmitCb = () => {} }) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: Object.fromEntries(strategicScoresFields.map(({ value }) => [value, false])),
  });

  useEffect(() => {
    if (strategicScores.length) {
      strategicScores.map(key => setValue(key, true));
    }
  }, [strategicScores, setValue]);

  const score = Object.values(watch()).filter(Boolean).length;

  const onSubmit = useCallback(
    async data => {
      setLoading(true);
      const newStrategicScores = Object.entries(data)
        .filter(([, value]) => value)
        .map(([key]) => key);

      await apiPutEvent(eventId, { strategicScores: newStrategicScores, score: newStrategicScores.length });
      setLoading(false);

      onSubmitCb(newStrategicScores);
    },
    [eventId, onSubmitCb, setLoading],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h3" paragraph style={{ textTransform: "capitalize" }}>
        <strong>{t("Strategic Score")}</strong>
      </Typography>

      <Box mb={3} display="flex" alignItems="center">
        <Typography variant="body1">{t("score").toUpperCase()}</Typography>
        <Box ml={1}>
          <IconWrapper>
            <StarIcon number={score} />
          </IconWrapper>
        </Box>
      </Box>

      <Grid container spacing={3}>
        {strategicScoresFields.map(({ value, label }) => (
          <Grid key={value} item xs={12} sm={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <FormControlLabel
              label={t(label)}
              control={<Controller control={control} as={<Checkbox />} name={value} />}
            />
          </Grid>
        ))}

        <Grid item xs={12}>
          <Box mt={3}>
            <Button type="submit" variant="contained" color="primary">
              {t("Save")}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <FormLoader isLoading={isLoading} />
    </form>
  );
};

StrategicScoreForm.propTypes = {
  eventId: PropTypes.string,
  strategicScores: PropTypes.arrayOf(PropTypes.string),
  onSubmitCb: PropTypes.func,
};

export default StrategicScoreForm;
