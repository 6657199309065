/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

const ConcertIcon = ({ width = 20, height = 20, fill = "#203934", className = "" }) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 20 20" className={className}>
    <path
      d="M6.48433 3.51587C5.51504 3.51587 4.72656 4.30438 4.72656 5.27364C4.72656 6.2429 5.51504 7.03142 6.48433 7.03142C7.45359 7.03142 8.2421 6.2429 8.2421 5.27364C8.2421 4.30435 7.45359 3.51587 6.48433 3.51587ZM6.48433 5.85954C6.16074 5.85954 5.8984 5.5972 5.8984 5.2736C5.8984 4.94997 6.16074 4.68767 6.48433 4.68767C6.80793 4.68767 7.07027 4.94993 7.07027 5.2736C7.07023 5.59724 6.80789 5.85954 6.48433 5.85954Z"
      fill={fill}
    />
    <path
      d="M6.48434 10.5471C4.86902 10.5471 3.55469 11.8614 3.55469 13.4767C3.55469 15.092 4.86898 16.4064 6.48434 16.4064C8.09965 16.4064 9.41398 15.0921 9.41398 13.4767C9.41395 11.8614 8.09965 10.5471 6.48434 10.5471ZM6.48434 15.2345C5.51504 15.2345 4.72656 14.446 4.72656 13.4768C4.72656 12.5075 5.51504 11.719 6.48434 11.719C7.45359 11.719 8.24211 12.5075 8.24211 13.4768C8.24211 14.446 7.45359 15.2345 6.48434 15.2345Z"
      fill={fill}
    />
    <path
      d="M6.48438 12.8909C6.16109 12.8909 5.89844 13.1535 5.89844 13.4768C5.89844 13.8001 6.16105 14.0627 6.48438 14.0627C6.80766 14.0627 7.07031 13.8001 7.07031 13.4768C7.07027 13.1535 6.80762 12.8909 6.48438 12.8909Z"
      fill={fill}
    />
    <path
      d="M19.5556 1.19001L14.8681 0.0181379C14.5729 -0.0579559 14.2645 0.111966 14.1695 0.404388L13.3997 2.76212C13.2129 2.61841 12.9957 2.50884 12.7527 2.44884C11.8137 2.21767 10.8576 2.79329 10.6247 3.73341V3.734C10.3924 4.67411 10.9686 5.62911 11.9098 5.86201C12.8147 6.08638 13.7219 5.55872 13.9977 4.66829C14.0691 4.50595 15.0585 1.45595 15.1135 1.28736L18.6388 2.16857L17.9945 3.92685C17.7979 3.76443 17.5671 3.63829 17.3033 3.57322C16.3654 3.34146 15.4082 3.91767 15.1753 4.85833C14.941 5.8047 15.5209 6.75451 16.4599 6.98576C17.3827 7.21365 18.2833 6.66447 18.5514 5.78533C18.5554 5.77576 18.5659 5.77173 18.5695 5.76185L19.9634 1.95962C20.0838 1.63251 19.8924 1.27361 19.5556 1.19001Z"
      fill={fill}
    />
    <path
      d="M19.8278 9.54697L18.656 8.3751C18.5106 8.22862 18.3001 8.16912 18.0969 8.22174C17.8966 8.27268 17.7381 8.42545 17.6797 8.62342L17.0019 10.9199C16.7914 10.7594 16.5568 10.6337 16.2893 10.5809C15.3236 10.3914 14.4096 11.0216 14.2237 11.9628C14.0338 12.9179 14.6535 13.8402 15.6049 14.0284C16.4222 14.1942 17.26 13.7994 17.5804 12.8674C17.6223 12.8123 18.5243 9.90057 18.5243 9.90057L18.9992 10.3755C19.2281 10.6044 19.5989 10.6044 19.8277 10.3755C20.0567 10.1466 20.0567 9.7758 19.8278 9.54697Z"
      fill={fill}
    />
    <path
      d="M11.6279 6.99959C10.0606 6.61162 9.06082 5.02037 9.4482 3.45314C9.77266 2.1417 10.9818 1.22615 12.3334 1.22615C12.4432 1.22615 12.5531 1.23244 12.6624 1.24447L12.76 0.945567C12.4653 0.386582 11.8852 0.000488281 11.2107 0.000488281H1.75777C0.788477 0.000488281 0 0.789004 0 1.75826V18.2423C0 19.2117 0.788437 20.0001 1.75777 20.0001H11.2107C12.18 20.0001 12.9685 19.2116 12.9685 18.2423V7.00654C12.5439 7.10193 12.1311 7.12479 11.6279 6.99959ZM6.48426 2.3442C8.09957 2.3442 9.41391 3.6585 9.41391 5.27385C9.41391 6.88916 8.09961 8.20346 6.48426 8.20346C4.86891 8.20346 3.55461 6.88916 3.55461 5.27385C3.55461 3.6585 4.86891 2.3442 6.48426 2.3442ZM6.48426 17.5783C4.22293 17.5783 2.38277 15.7381 2.38277 13.4768C2.38277 11.2155 4.22293 9.37533 6.48426 9.37533C8.74559 9.37533 10.6248 11.2155 10.6248 13.4768C10.6248 15.7381 8.74555 17.5783 6.48426 17.5783Z"
      fill={fill}
    />
  </svg>
);

ConcertIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default ConcertIcon;
