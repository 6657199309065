import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { pathRoutes } from "../constants";
import { useAuth } from "../Provider/AuthProvider";
import PrivateRoute from "../Components/PrivateRoute";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

// Pages
import SignInPage from "./SignInPage";
import CreateEventPage from "./CreateEventPage";
import AdminEventsListPage from "./AdminEventsListPage";
import EventsPage from "./EventsPage";
import EventPage from "./EventPage";

const Routers = () => {
  const { user } = useAuth();

  return (
    <>
      {user && <Header />}

      <main style={{ flexGrow: "1" }}>
        <Switch>
          <Redirect exact from="/" to={pathRoutes.root} />
          <Redirect exact from={pathRoutes.events} to={`${pathRoutes.events}/${pathRoutes.list}`} />

          <Route exact path={pathRoutes.signIn} component={SignInPage} />
          <PrivateRoute exact path={`${pathRoutes.events}/:${pathRoutes.eventsType}`} component={EventsPage} />
          <PrivateRoute exact path={`${pathRoutes.event}/:${pathRoutes.eventId}`} component={EventPage} />

          <PrivateRoute
            onlySuperAdmin
            path={[`${pathRoutes.createEvent}/:${pathRoutes.eventId}`, pathRoutes.createEvent]}
            component={CreateEventPage}
          />
          <PrivateRoute onlySuperAdmin path={pathRoutes.adminEvents} component={AdminEventsListPage} />
        </Switch>
      </main>

      {user && <Footer />}
    </>
  );
};

export default Routers;
