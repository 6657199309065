import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { pathRoutes } from "../constants";
import { apiGetEvent } from "../services";
import useLoadData from "../hooks/useLoadData";
import CreateEventForm from "../Components/CreateEventForm";
import StrategicScoreForm from "../Components/StrategicScoreForm";
import EventOthersForm from "../Components/EventOthersForm";
import PageLoader from "../Components/PageLoader";
import PageErrorMessage from "../Components/PageErrorMessage";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

const CreateEventPage = ({ match = {} }) => {
  const eventId = match.params[pathRoutes.eventId];
  const [{ data: event, isLoading, error }, setEvent] = useLoadData(undefined, apiGetEvent, !eventId, eventId);
  const { t } = useTranslation();
  const history = useHistory();

  if (eventId && isLoading) return <PageLoader />;
  if (error) return <PageErrorMessage error={error} />;

  return (
    <Container maxWidth="md">
      <Box mb={4.3}>
        <Paper variant="outlined" square>
          <Box py={3} px={3.5} position="relative">
            <CreateEventForm
              eventId={eventId}
              event={event || {}}
              onSubmitCb={(id, newEvent) => {
                if (!eventId) {
                  history.replace(`${pathRoutes.createEvent}/${id}`);
                  setEvent(newEvent);
                }
              }}
            />
          </Box>
        </Paper>
      </Box>

      {eventId && (
        <Box mb={4.3}>
          <Paper variant="outlined" square>
            <Box py={3} px={3.5} position="relative">
              <StrategicScoreForm eventId={eventId} strategicScores={event?.strategicScores} />
            </Box>
          </Paper>
        </Box>
      )}

      {eventId && (
        <Box mb={2}>
          <Paper variant="outlined" square>
            <Box py={3} px={3.5} position="relative">
              <EventOthersForm eventId={eventId} event={event || {}} />
            </Box>
          </Paper>
        </Box>
      )}

      <Box mb={4} display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={() => history.push(pathRoutes.adminEvents)}>
          {t("Events")} {t("list")}
        </Button>
      </Box>
    </Container>
  );
};

CreateEventPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      [pathRoutes.eventId]: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default CreateEventPage;
