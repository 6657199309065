import React from "react";
import PropTypes from "prop-types";
import { eventPropTypes } from "../constants";
import EventsListItem from "./EventsListItem";
import Grid from "@material-ui/core/Grid";

const EventsList = ({ events = [] }) => (
  <Grid container spacing={2}>
    {events.map(event => (
      <Grid key={event.id} item xs={12} md={6}>
        <EventsListItem event={event} />
      </Grid>
    ))}
  </Grid>
);

EventsList.propTypes = {
  events: PropTypes.arrayOf(eventPropTypes),
};

export default EventsList;
