import React, { Fragment, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import startOfToday from "date-fns/startOfToday";
import endOfToday from "date-fns/endOfToday";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  eventTypeFields,
  eventCategoryFields,
  eventPropTypes,
  createEventFormDateFormat,
  locationsFields,
  imageFileTypes,
  originImagePostfix,
} from "../constants";
import { apiPostEvent, apiPutEvent, deleteEventImage, uploadEventImage } from "../services";
import { isEmptyObject, transformToSelectOptions } from "../helpers";
import ImageCropperUploader from "./ImageCropperUploader";
import FormLoader from "./FormLoader";
import { DateTimePicker } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import StartCalendarIcon from "@material-ui/icons/Today";
import EndCalendarIcon from "@material-ui/icons/Event";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

const saveEventImages = async (imageName, { sourceImageUrl, croppedImage }) => {
  const getUrlCb = snapshot => snapshot.ref.getDownloadURL();
  const [originalUrl, url] = await Promise.all([
    sourceImageUrl
      ? fetch(sourceImageUrl)
          .then(response => response.blob())
          .then(sourceImage =>
            uploadEventImage(`${imageName}${originImagePostfix}.${imageFileTypes[sourceImage.type]}`, sourceImage),
          )
          .then(getUrlCb)
      : "",
    croppedImage
      ? uploadEventImage(`${imageName}.${imageFileTypes[croppedImage.type]}`, croppedImage).then(getUrlCb)
      : "",
  ]);

  return { originalUrl, url };
};

const createId = () => String(Math.random()).slice(2);

const CreateEventForm = ({ eventId, event = {}, onSubmitCb = () => {} }) => {
  const isEditForm = Boolean(eventId);
  const startDateDefault = startOfToday();
  const defaultValues = {
    name: "",
    type: "",
    category: "",
    startDate: new Date(startDateDefault).toISOString(),
    endDate: new Date(endOfToday()).toISOString(),
    location: "",
    imageData: {},
    description: "",
    link: "",
  };

  const { t } = useTranslation();
  const [alternativeDates, setAlternativeDates] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [eventImage, setEventImage] = useState({
    imageData: event?.imageData,
  });
  const { control, handleSubmit, setValue, watch, errors } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (isEditForm && !isEmptyObject(event)) {
      const defaultValuesKeys = Object.keys(defaultValues);
      Object.entries(event)
        .filter(([key]) => defaultValuesKeys.includes(key))
        .forEach(params => setValue(...params));

      if (Array.isArray(event.alternativeStartDates) && Array.isArray(event.alternativeEndDates)) {
        setAlternativeDates(event.alternativeStartDates.map(() => createId()));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditForm, event, setValue, setAlternativeDates]);

  const onSubmit = useCallback(
    async data => {
      if (!isEmptyObject(errors)) return;

      setLoading(true);

      let id = eventId;
      const { alternativeStartDates, alternativeEndDates, ...params } = data;
      const isEmptyImage = isEmptyObject(eventImage);
      let newEvent = Object.fromEntries([
        ...Object.entries(params).map(([key, value]) => {
          if (["startDate", "endDate"].includes(key)) {
            return [key, new Date(value).toISOString()];
          }
          return [key, value];
        }),
        ["imageData", {}],
      ]);

      if (alternativeStartDates && alternativeEndDates) {
        const transformDate = date => new Date(date).toISOString();
        newEvent = {
          ...newEvent,
          alternativeStartDates: Object.values(alternativeStartDates).map(transformDate),
          alternativeEndDates: Object.values(alternativeEndDates).map(transformDate),
        };
      }

      if (!isEditForm) {
        const eventEmpty = await apiPostEvent({});
        id = eventEmpty.id;
      }

      if (!isEmptyImage) {
        const { originalUrl, url } = await saveEventImages(id, eventImage);

        newEvent = {
          ...newEvent,
          imageData: {
            ...eventImage.imageData,
            ...(originalUrl && { originalUrl }),
            ...(url && { url }),
          },
        };
      } else if (event.imageData?.url) {
        try {
          const imgType = imageFileTypes[event.imageData?.fileType];
          await deleteEventImage(`${id}${originImagePostfix}.${imgType}`);
          await deleteEventImage(`${id}.${imgType}`);
        } catch (error) {
          console.error(error);
        }
      }

      await apiPutEvent(id, newEvent);
      setLoading(false);

      onSubmitCb(id, newEvent);
    },
    [isEditForm, eventId, event, eventImage, errors, onSubmitCb, setLoading],
  );

  const translateLabel = item => ({
    ...item,
    label: t(item.label),
  });

  return (
    <div>
      <Typography
        variant="h4"
        component="h2"
        paragraph
        style={{
          textTransform: "capitalize",
        }}
      >
        {eventId ? t("Edit") : t("Create")} {t("event")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              as={TextField}
              name="name"
              control={control}
              rules={{ required: true }}
              fullWidth
              required
              label={t("Name")}
              defaultValue=""
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={errors.type?.type}>
              <InputLabel htmlFor="type">{t("Type")}</InputLabel>
              <Controller
                required
                name="type"
                as={<Select id="type">{transformToSelectOptions(eventTypeFields.map(translateLabel))}</Select>}
                control={control}
                rules={{ required: true }}
                onChange={([event]) => event.target.value}
              />
              <FormHelperText>{errors.type?.type}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="category">{t("Category")}</InputLabel>
              <Controller
                name="category"
                as={
                  <Select id="category">
                    {transformToSelectOptions(eventCategoryFields.map(translateLabel), true)}
                  </Select>
                }
                control={control}
                onChange={([event]) => event.target.value}
              />
              <FormHelperText>{errors.category?.type}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              as={<DateTimePicker />}
              rules={{ required: true }}
              name="startDate"
              fullWidth
              required
              showTodayButton
              ampm={false}
              format={createEventFormDateFormat}
              label={`${t("Start")} ${t("date")}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <StartCalendarIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              as={<DateTimePicker />}
              rules={{ required: true }}
              name="endDate"
              fullWidth
              required
              showTodayButton
              ampm={false}
              format={createEventFormDateFormat}
              label={`${t("End")} ${t("date")}`}
              minDate={watch("startDate")}
              minDateMessage={errors.endDate && t("Date should not be before start date")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <EndCalendarIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {alternativeDates.map((id, index) => (
            <Fragment key={id}>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  as={<DateTimePicker />}
                  rules={{ required: true }}
                  name={`alternativeStartDates.${id}`}
                  fullWidth
                  required
                  showTodayButton
                  ampm={false}
                  format={createEventFormDateFormat}
                  label={`${t("Start")} ${t("date")}`}
                  defaultValue={
                    (event.alternativeStartDates || [])[index] ||
                    watch(`alternativeStartDates.${alternativeDates?.[index - 1]}`) ||
                    watch("startDate")
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <StartCalendarIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={10} sm={5}>
                <Controller
                  control={control}
                  as={<DateTimePicker />}
                  rules={{ required: true }}
                  name={`alternativeEndDates.${id}`}
                  fullWidth
                  required
                  showTodayButton
                  ampm={false}
                  format={createEventFormDateFormat}
                  label={`${t("End")} ${t("date")}`}
                  minDate={watch(`alternativeStartDates.${id}`)}
                  minDateMessage={errors[`alternativeEndDates.${id}`] && t("Date should not be before start date")}
                  defaultValue={
                    (event.alternativeEndDates || [])[index] ||
                    watch(`alternativeEndDates.${alternativeDates?.[index - 1]}`) ||
                    watch("endDate")
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <EndCalendarIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={1}>
                <Fab
                  size="small"
                  onClick={() => setAlternativeDates((items = []) => items.filter(itemId => itemId !== id))}
                >
                  <DeleteIcon fontSize="small" />
                </Fab>
              </Grid>
            </Fragment>
          ))}

          <Grid item xs={12}>
            <Fab
              size="small"
              color="default"
              onClick={() => {
                setAlternativeDates((items = []) => [...items, createId()]);
              }}
            >
              <AddIcon />
            </Fab>
          </Grid>

          <Grid container item>
            <Grid item xs={12}>
              <Typography variant="h6" paragraph>
                {t("Image")}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <ImageCropperUploader imageData={event?.imageData} onSave={setEventImage} />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={errors.location?.type}>
              <InputLabel htmlFor="location">{t("Location")}</InputLabel>
              <Controller
                required
                name="location"
                as={<Select id="location">{transformToSelectOptions(locationsFields.map(translateLabel))}</Select>}
                control={control}
                rules={{ required: true }}
                onChange={([event]) => event.target.value}
              />
              <FormHelperText>{errors.location?.type}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Controller
              as={TextField}
              name="description"
              control={control}
              fullWidth
              label={t("Description")}
              defaultValue=""
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              as={TextField}
              type="url"
              name="link"
              control={control}
              rules={{
                pattern: "https?://.+",
              }}
              fullWidth
              label={t("Link")}
              defaultValue=""
            />
          </Grid>

          <Grid item xs={12}>
            <Box mt={3}>
              <Button type="submit" variant="contained" color="primary" disabled={!isEmptyObject(errors)}>
                {t("Save")}
              </Button>
            </Box>
          </Grid>
        </Grid>

        <FormLoader isLoading={isLoading} />
      </form>
    </div>
  );
};

CreateEventForm.propTypes = {
  eventId: PropTypes.string,
  event: eventPropTypes,
  onSubmitCb: PropTypes.func,
};

export default CreateEventForm;
