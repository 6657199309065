import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { OverlayView, GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { eventPropTypes, googleMapsApiKey, locationsCoords } from "../constants";
import EventsMapCard from "./EventsMapCard";
import markerImage from "../assets/marker.png";
import Container from "@material-ui/core/Container";
import PageLoader from "./PageLoader";
import PageErrorMessage from "./PageErrorMessage";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  events: {
    width: 340,
    height: 129,
    boxShadow:
      "0 0 6px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25)",
    overflow: "hidden scroll",
  },
});

const center = {
  lat: 55.6713108,
  lng: 12.5588047,
};

const options = {
  center,
  zoom: 13,
  gestureHandling: "cooperative",
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  styles: [
    {
      featureType: "administrative.land_parcel",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.business",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
};

const mapContainerStyle = {
  width: "100%",
  paddingBottom: "55%",
};

const EventsMap = ({ events = [] }) => {
  const classes = useStyles();
  const [openEvents, setOpenEvents] = useState([]);
  const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey });
  const groupedEvents = events.reduce(
    (acc, event) => ({ ...acc, [event.location]: (acc[event.location] || []).concat([event]) }),
    {},
  );

  if (loadError) return <PageErrorMessage error="Map cannot be loaded right now, sorry." />;
  if (!isLoaded) return <PageLoader />;

  return (
    <Container maxWidth="xl">
      <Box mt={2} mb={6.75}>
        <GoogleMap options={options} mapContainerStyle={mapContainerStyle}>
          {Object.entries(groupedEvents).map(([location, events]) => {
            const position = locationsCoords[location] || center;
            return (
              <Fragment key={location}>
                <Marker
                  icon={markerImage}
                  id={location}
                  position={position}
                  onClick={() =>
                    setOpenEvents(state =>
                      state.includes(location) ? state.filter(id => id !== location) : state.concat([location]),
                    )
                  }
                />
                <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                  {openEvents.includes(location) ? (
                    <div className={classes.events}>
                      {events.map((event, index) => (
                        <EventsMapCard
                          key={event.id}
                          event={event}
                          isFirst={!index}
                          isLast={index === events.length - 1}
                        />
                      ))}
                    </div>
                  ) : (
                    <div />
                  )}
                </OverlayView>
              </Fragment>
            );
          })}
        </GoogleMap>
      </Box>
    </Container>
  );
};

EventsMap.propTypes = {
  events: PropTypes.arrayOf(eventPropTypes),
};

export default EventsMap;
