/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

const ExhibitionIcon = ({ width = 18, height = 20, fill = "#203934", className = "" }) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 18 20" className={className}>
    <path
      d="M0.585948 2.34379C0.262075 2.34379 0 2.60587 0 2.92974V10.6159C0.414578 10.6163 0.832437 10.7304 1.20393 10.9775C1.54038 11.2018 1.97527 11.2018 2.31172 10.9775C3.03928 10.4925 3.97808 10.4918 4.70778 10.972C4.7306 10.5088 4.822 10.0641 4.98666 9.65342L3.42178 8.74989C3.14139 8.58793 3.04525 8.22976 3.20721 7.94937C3.36916 7.66897 3.72792 7.57342 4.00773 7.73479L5.5837 8.64477C5.78319 8.39234 6.00941 8.16613 6.26184 7.96663L5.35186 6.39067C5.1899 6.11027 5.28604 5.75206 5.56643 5.59014C5.84452 5.42705 6.20445 5.52319 6.36695 5.80472L7.27049 7.36959C7.56538 7.25127 7.87933 7.1767 8.2032 7.1297V5.3126C8.2032 4.98873 8.46527 4.72665 8.78915 4.72665C9.11302 4.72665 9.3751 4.98873 9.3751 5.3126V7.12966C9.69897 7.17666 10.0129 7.25127 10.3078 7.36955L11.2113 5.80468C11.3733 5.52315 11.7309 5.42702 12.0119 5.5901C12.2923 5.75206 12.3884 6.11023 12.2264 6.39063L11.3165 7.96659C11.5689 8.16609 11.7951 8.39231 11.9946 8.64473L13.5706 7.73475C13.8492 7.57338 14.2092 7.66893 14.3711 7.94933C14.533 8.22972 14.4369 8.58793 14.1565 8.74985L12.5916 9.65338C12.7563 10.0641 12.8477 10.5087 12.8705 10.9719C13.6002 10.4918 14.539 10.4925 15.2666 10.9775C15.6031 11.2018 16.038 11.2018 16.3744 10.9775C16.7344 10.7372 17.1543 10.6218 17.5783 10.6217V2.92974C17.5783 2.60587 17.3163 2.34379 16.9924 2.34379H11.9431L11.7966 1.1719H12.3048C12.6287 1.1719 12.8907 0.909822 12.8907 0.585948C12.8907 0.262075 12.6287 0 12.3048 0H5.27342C4.94955 0 4.68747 0.262075 4.68747 0.585948C4.68747 0.909822 4.94955 1.1719 5.27342 1.1719H5.78163L5.63514 2.34379H0.585948ZM6.96263 1.1719H10.6158L10.7623 2.34379H6.81614L6.96263 1.1719Z"
      fill={fill}
    />
    <path
      d="M3.91163e-05 14.7267C3.91163e-05 15.0506 0.262114 15.3126 0.585988 15.3126H4.02394L3.87746 16.4845H2.92978C2.60591 16.4845 2.34383 16.7466 2.34383 17.0705C2.34383 17.3944 2.60591 17.6564 2.92978 17.6564H3.73097L3.52034 19.3416C3.4803 19.6626 3.70804 19.9556 4.02902 19.9957C4.36399 20.0346 4.64407 19.7975 4.68306 19.487L4.91189 17.6565H12.6667L12.8955 19.487C12.9345 19.7978 13.2149 20.0346 13.5496 19.9957C13.8706 19.9556 14.0983 19.6626 14.0582 19.3416L13.8476 17.6564H14.6488C14.9726 17.6564 15.2347 17.3944 15.2347 17.0705C15.2347 16.7466 14.9726 16.4845 14.6488 16.4845H13.7011L13.5546 15.3126H16.9925C17.3164 15.3126 17.5785 15.0506 17.5785 14.7267V11.8061C17.3831 11.8061 17.1902 11.8422 17.0246 11.9524C16.2933 12.4399 15.348 12.4399 14.6167 11.9524C14.2802 11.7281 13.8453 11.7281 13.5089 11.9524C12.7759 12.441 11.8148 12.4282 11.101 11.9524C10.7645 11.7281 10.3296 11.7281 9.99319 11.9524C9.26189 12.4399 8.3166 12.4399 7.5853 11.9524C7.24885 11.7281 6.81396 11.7281 6.4775 11.9524C5.79835 12.4051 4.81185 12.4472 4.06961 11.9524C3.73316 11.7281 3.29826 11.7281 2.96181 11.9524C2.23051 12.4399 1.28522 12.4399 0.553917 11.9524C0.382742 11.839 0.190707 11.7985 0 11.7985V14.7267H3.91163e-05ZM12.3737 15.3126L12.5202 16.4845H5.05834L5.20482 15.3126H12.3737Z"
      fill={fill}
    />
    <path
      d="M8.78838 8.24243C7.24839 8.24243 5.99574 9.44085 5.88086 10.952C6.60459 10.4981 7.52027 10.5015 8.23446 10.9776C8.57092 11.2019 9.00581 11.2019 9.34226 10.9776C10.0565 10.5015 10.9721 10.4981 11.6959 10.952C11.581 9.44085 10.3284 8.24243 8.78838 8.24243Z"
      fill={fill}
    />
  </svg>
);

ExhibitionIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default ExhibitionIcon;
