import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { pathRoutes } from "../constants";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import logoImage from "../assets/logo.png";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useAuth } from "../Provider/AuthProvider";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(2.1),
      paddingBottom: theme.spacing(2.1),
      paddingLeft: theme.spacing(5.25),
      marginBottom: theme.spacing(5.125),
    },
  },
  rootBox: {
    borderBottom: "1px solid #f2f2f2",
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
  navigationButtons: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
      "& > *": {
        marginRight: theme.spacing(6),
      },
    },
  },
  activeLink: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.main,
  },
}));

const Header = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuth();
  const singlePageMatch = useRouteMatch(`${pathRoutes.event}/:${pathRoutes.eventId}`);
  const isSinglePageMatch = singlePageMatch?.isExact;

  return (
    <Container maxWidth={false} className={clsx(classes.root, isSinglePageMatch && classes.rootBox)} component="header">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3}>
          <Box mt={0.5} className={classes.logoWrapper}>
            <a href={pathRoutes.root}>
              <img src={logoImage} alt="Wonderful Copenhagen" width="79" height="31" />
            </a>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={9}>
          <div className={classes.navigationButtons}>
            {user.superAdmin && (
              <Button component={NavLink} to={pathRoutes.adminEvents} activeClassName={classes.activeLink}>
                {t("Administrator events")}
              </Button>
            )}
            <Button
              component={NavLink}
              to={`${pathRoutes.events}/${pathRoutes.gantt}`}
              activeClassName={classes.activeLink}
            >
              {t("Gantt")} {t("Chart")}
            </Button>
            <Button
              component={NavLink}
              to={`${pathRoutes.events}/${pathRoutes.list}`}
              activeClassName={classes.activeLink}
            >
              {t("Card List")}
            </Button>
            <Button
              component={NavLink}
              to={`${pathRoutes.events}/${pathRoutes.map}`}
              activeClassName={classes.activeLink}
            >
              {t("Map View")}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Header;
