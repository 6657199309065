/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

const NationalIcon = ({ width = 20, height = 20, fill = "#ef264d", className = "" }) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 20 20" className={className}>
    <rect x="0.25" y="3.16675" width="19.5" height="13.7857" fill="white" stroke="#F5F5F5" strokeWidth="0.5" />
    {/*<mask id="mask0" maskType="alpha" maskUnits="userSpaceOnUse" x="0" y="2" width="20" height="16">*/}
    {/*  <rect y="2.91675" width="20" height="14.2857" fill="white" />*/}
    {/*</mask>*/}
    {/*<g mask="url(#mask0)">*/}
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.71429 2.91675H0V8.63103H5.71429V2.91675ZM8.57143 2.91675V8.63103H20V2.91675H8.57143ZM20 11.4882H8.57143V17.2025H20V11.4882ZM5.71429 17.2025V11.4882H0V17.2025H5.71429Z"
    />
    {/*</g>*/}
  </svg>
);

NationalIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default NationalIcon;
