import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { allowedImageTypes } from "../constants";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const styles = () => ({
  root: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: "1",
    cursor: "pointer",
  },

  input: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: "2",
    width: "100%",
    height: "100%",
    opacity: "0",
    cursor: "pointer",

    ".disabled &": {
      visibility: "hidden",
      pointerEvents: "none",
    },
    ".active &": {
      visibility: "visible",
      pointerEvents: "auto",
      zIndex: "3",
    },

    "&:hover + label": {
      backgroundColor: "#adadad",
    },
  },

  label: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
    color: "white",
    backgroundColor: "#c4c4c4",
    transition: "all 0.2s ease",

    "& svg": {
      width: 78,
      height: 78,
    },
    ".disabled &": {
      opacity: "0",
    },
    ".active &": {
      zIndex: "2",
      opacity: "1",
    },
  },
});

const highlight = event => {
  const el = event.currentTarget;

  if (!el.classList.contains("active")) {
    el.classList.add("active");
  }
};

const unHighlight = event => {
  if (event.target.tagName === "INPUT") {
    event.currentTarget.classList.remove("active");

    if (event.currentTarget.tagName === "INPUT") {
      event.currentTarget.parentElement.classList.remove("active");
    }
  }
};

const FileUpload = ({ isShowDNDArea = true, onUpload, classes, children }) => (
  <div
    className={`${classes.root} ${!isShowDNDArea ? "disabled" : ""}`}
    onDragOver={highlight}
    onDragLeave={unHighlight}
  >
    <input
      className={classes.input}
      id="fileElem"
      type="file"
      accept={allowedImageTypes.join(",")}
      onDrop={unHighlight}
      onChange={event => {
        event.preventDefault();
        onUpload(event.target.files[0]);
      }}
    />
    <label className={classes.label} htmlFor="fileElem">
      <CloudUploadIcon fontSize="large" />
    </label>
    {!isShowDNDArea && children}
  </div>
);

FileUpload.propTypes = {
  isShowDNDArea: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default withStyles(styles)(FileUpload);
