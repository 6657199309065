import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ageGroupFields,
  audienceFields,
  eventPropTypes,
  participantsFields,
  priceFields,
  eventMoreFields,
  tagsFields,
} from "../constants";
import { apiPutEvent } from "../services";
import FormLoader from "./FormLoader";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { isEmptyObject, transformToSelectOptions } from "../helpers/utils";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconWrapper from "./IconWrapper";

const EventOthersForm = ({ eventId, event = {}, onSubmitCb = () => {} }) => {
  const defaultValues = {
    tags: Object.fromEntries(tagsFields.map(({ value }) => [value, false])),
    ageGroup: "all",
    price: "",
    indoors: false,
    outdoor: false,
    international: false,
    recurring: false,
    subsidiary: false,
    primaryFocus: false,
    moves: false,
    troubles: false,
    excitement: false,
    participants: "",
    audience: "",
  };
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const { control, handleSubmit, setValue, errors } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (!isEmptyObject(event)) {
      const defaultValuesKeys = Object.keys(defaultValues);

      Object.entries(event)
        .filter(([key]) => defaultValuesKeys.includes(key))
        .map(params => setValue(...params));
    }
    if ((event?.tags || []).length) {
      event.tags.map(key => setValue(`tags.${key}`, true));
    }
    if (event?.primaryFocus === "btb") {
      setValue("primaryFocus", false);
    } else {
      setValue("primaryFocus", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, setValue]);

  const onSubmit = useCallback(
    async data => {
      setLoading(true);

      const newEvent = {
        ...data,
        price: Number(data.price),
        participants: data.participants ? Number(data.participants) : "",
        audience: data.audience ? Number(data.audience) : "",
        primaryFocus: data.primaryFocus ? "btc" : "btb",
        tags: Object.entries(data.tags || {})
          .filter(([, value]) => value)
          .map(([key]) => key),
      };

      await apiPutEvent(eventId, newEvent);
      setLoading(false);

      onSubmitCb(newEvent);
    },
    [eventId, onSubmitCb, setLoading],
  );

  const translateLabel = item => ({ ...item, label: t(item.label) });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={5}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h3" style={{ textTransform: "capitalize" }}>
              <strong>{t("Brand themes")}</strong>
            </Typography>
          </Grid>
          {tagsFields.map(({ value, label }) => (
            <Grid key={value} item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
              <FormControlLabel
                label={t(label)}
                control={<Controller control={control} as={<Checkbox />} name={`tags.${value}`} />}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mb={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <Typography variant="h5" component="h3" style={{ textTransform: "capitalize" }}>
              <strong>{t("Filters")}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={errors.ageGroup?.type}>
              <InputLabel htmlFor="ageGroup">{t("Age Group")}</InputLabel>
              <Controller
                required
                name="ageGroup"
                as={<Select id="ageGroup">{transformToSelectOptions(ageGroupFields)}</Select>}
                control={control}
                rules={{ required: true }}
                onChange={([event]) => event.target.value}
              />
              <FormHelperText>{errors.ageGroup?.type}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={errors.price?.type}>
              <InputLabel htmlFor="price">{t("Price Range")}</InputLabel>
              <Controller
                required
                name="price"
                as={<Select id="price">{transformToSelectOptions(priceFields)}</Select>}
                control={control}
                rules={{ required: true }}
                onChange={([event]) => event.target.value}
              />
              <FormHelperText>{errors.price?.type}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box mb={5}>
        <Box mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ paddingBottom: 0 }}>
              <Typography variant="h5" component="h3" style={{ textTransform: "capitalize" }}>
                <strong>{t("More Filters")}</strong>
              </Typography>
            </Grid>
            {eventMoreFields.map(({ value, label, Icon }) => (
              <Grid key={value} item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FormControlLabel
                  label={
                    <Box display="flex" alignItems="center">
                      {Icon && (
                        <Box mr={1}>
                          <IconWrapper>
                            <Icon />
                          </IconWrapper>
                        </Box>
                      )}
                      <Typography variant="subtitle2" component="p">
                        {t(label)}
                      </Typography>
                    </Box>
                  }
                  control={<Controller control={control} as={<Checkbox />} name={value} />}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={errors.audience?.type}>
              <InputLabel htmlFor="participants">{t("Participants")}</InputLabel>
              <Controller
                name="participants"
                as={
                  <Select id="participants">
                    {transformToSelectOptions(participantsFields.map(translateLabel), true)}
                  </Select>
                }
                control={control}
                onChange={([event]) => event.target.value}
              />
              <FormHelperText>{errors.participants?.type}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} error={errors.audience?.type}>
            <FormControl fullWidth>
              <InputLabel htmlFor="audience">{t("Audience")}</InputLabel>
              <Controller
                name="audience"
                as={<Select id="audience">{transformToSelectOptions(audienceFields.map(translateLabel))}</Select>}
                control={control}
                onChange={([event]) => event.target.value}
              />
              <FormHelperText>{errors.audience?.type}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box mt={3}>
        <Button type="submit" variant="contained" color="primary">
          {t("Save")}
        </Button>
      </Box>

      <FormLoader isLoading={isLoading} />
    </form>
  );
};

EventOthersForm.propTypes = {
  eventId: PropTypes.string,
  event: eventPropTypes,
  onSubmitCb: PropTypes.func,
};

export default EventOthersForm;
