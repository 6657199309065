/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

function useLoadData(initialData = null, apiCb = () => {}, isLoaded, ...apiParams) {
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(!isLoaded);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
    setIsLoading(true);
    if (!isLoaded && apiParams.every(param => param != null)) {
      apiCb(...apiParams)
        .then(res => setData(res))
        .finally(() => setIsLoading(false))
        .catch(error => {
          console.error(error);
          setError(error.message);
        });
    } else {
      setIsLoading(false);
    }
  }, [apiCb, isLoaded, ...apiParams]);

  return [{ data, isLoading, error }, setData];
}

export default useLoadData;
