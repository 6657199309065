/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import parseISO from "date-fns/parseISO";
import isToday from "date-fns/isToday";
import isThisWeek from "date-fns/isThisWeek";
import isThisMonth from "date-fns/isThisMonth";
import isThisYear from "date-fns/isThisYear";
import setHours from "date-fns/setHours";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import getDaysInMonth from "date-fns/getDaysInMonth";
import addDays from "date-fns/addDays";
import addWeeks from "date-fns/addWeeks";
import addMonths from "date-fns/addMonths";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import differenceInHours from "date-fns/differenceInHours";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import differenceInCalendarISOWeeks from "date-fns/differenceInCalendarISOWeeks";
import differenceInCalendarMonths from "date-fns/differenceInCalendarMonths";
import differenceInCalendarYears from "date-fns/differenceInCalendarYears";
import isFirstDayOfMonth from "date-fns/isFirstDayOfMonth";
import { makeStyles } from "@material-ui/core/styles";
import { eventPropTypes } from "../constants";
import { formatDate, getFirstDate } from "../helpers";
import usePrevious from "../hooks/usePrevious";
import useWindowSize from "../hooks/useWindowSize";
import ScrollBar from "./ScrollBar";
import ZoomButtons from "./ZoomButtons";
import GanttEventsList from "./GanttEventsList";
import IconsInfoDialog from "./IconsInfoDialog";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  wrapper: {
    [theme.breakpoints.down("md")]: {
      overflowX: "scroll",
    },
  },
  root: {
    marginTop: 12,
    display: "flex",
    [theme.breakpoints.down("md")]: {
      width: 1280,
    },
  },
  dateRangeFormControl: {
    minWidth: 210,
    height: 40,
    "& button": {
      width: "100%",
    },
  },
  eventsList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  title: {
    marginTop: 11,
  },
  ganntRoot: {
    "& ::-webkit-scrollbar": {
      display: "none",
    },
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    marginBottom: 11,
    "& > div:not(:last-child)": {
      marginRight: 10,
    },
  },
  ganttWrapper: {
    width: "100%",
    maxWidth: "100%",
    overflow: "scroll hidden",
    borderRight: "1px solid #e5e5e5",
  },
}));

const ganttTypes = {
  days: "days",
  weeks: "weeks",
  months: "months",
};

const getLabelsCols = (getLabelDate, addDate) => (startDate, diffEvents = 0) => [
  ...Array.from(Array(diffEvents))
    .map((_, diff) => getLabelDate(addDate(new Date(startDate), diff)))
    .reduce((acc, num) => acc.set(num, (acc.get(num) || 0) + 1), new Map())
    .entries(),
];

const getLabelDateDW = date => new Date().setFullYear(...date.split(":"));

const ganttConfig = {
  [ganttTypes.days]: {
    defaultColWidth: 32,
    minColWidth: 25,
    zoomMax: 2,
    labelShortDateFormat: "MMMMM",
    labelDateFormat: "MMMM yyyy",
    labelIsThisYearDateFormat: "MMMM",
    colLabelDateFormat: "dd",
    setRoundFirstEvent: date => startOfDay(date),
    setRoundLastEvent: date => addDays(endOfDay(date), 1),
    getEventColDivisor: () => 24,
    getDiffEvents: differenceInCalendarDays,
    getDiffEvent: differenceInHours,
    isFirstOfType: isFirstDayOfMonth,
    isNowOfType: isToday,
    getDiffLabelEvents: differenceInCalendarDays,
    getLabelDate: getLabelDateDW,
    getColsDate: addDays,
    getLabelsCols: getLabelsCols(date => `${getYear(date)}:${getMonth(date)}`, addDays),
  },
  [ganttTypes.weeks]: {
    defaultColWidth: 32 * 2,
    minColWidth: 30,
    zoomMax: 2,
    labelShortDateFormat: "MMMMM",
    labelDateFormat: "MMMM yyyy",
    labelIsThisYearDateFormat: "MMMM",
    colLabelDateFormat: "ww",
    setRoundFirstEvent: date => startOfWeek(date),
    setRoundLastEvent: date => addDays(endOfWeek(date), 1),
    getEventColDivisor: () => 24 * 7,
    getDiffEvents: differenceInCalendarISOWeeks,
    getDiffEvent: differenceInHours,
    isFirstOfType: (date, prevDate) => differenceInCalendarMonths(parseISO(date), parseISO(prevDate)) > 0,
    isNowOfType: isThisWeek,
    getDiffLabelEvents: differenceInCalendarDays,
    getLabelDate: getLabelDateDW,
    getColsDate: addWeeks,
    getLabelsCols: (...args) =>
      getLabelsCols(
        date => `${getYear(date)}:${getMonth(date)}`,
        addDays,
      )(...args).map(([num, cols]) => [num, cols / 7]),
  },
  [ganttTypes.months]: {
    defaultColWidth: 32 * 2,
    minColWidth: 39,
    zoomMax: 3,
    labelShortDateFormat: "yyyy",
    labelDateFormat: "yyyy",
    labelIsThisYearDateFormat: "yyyy",
    colLabelDateFormat: "MMM",
    setRoundFirstEvent: date => startOfMonth(date),
    setRoundLastEvent: date => addDays(endOfMonth(date), 1),
    getEventColDivisor: date => getDaysInMonth(date) * 24,
    getDiffEvents: differenceInCalendarMonths,
    getDiffEvent: differenceInHours,
    isFirstOfType: (date, prevDate) => differenceInCalendarYears(parseISO(date), parseISO(prevDate)) > 0,
    isNowOfType: isThisMonth,
    getDiffLabelEvents: differenceInCalendarMonths,
    getLabelDate: date => new Date().setFullYear(date),
    getColsDate: addMonths,
    getLabelsCols: getLabelsCols(getYear, addMonths),
  },
};

// let ticking = false;
let disableScrollBar = false;

const getFirsEvent = (events = []) =>
  [...events].sort(
    ({ startDate: a, alternativeStartDates: arrA = [] }, { startDate: b, alternativeStartDates: arrB = [] }) =>
      getFirstDate([a, ...arrA]) - getFirstDate([b, ...arrB]),
  )[0] || {};
const getLastEvent = (events = []) =>
  [...events].sort(
    ({ endDate: a, alternativeEndDates: arrA = [] }, { endDate: b, alternativeEndDates: arrB = [] }) =>
      getFirstDate([b, ...arrB], true) - getFirstDate([a, ...arrA], true),
  )[0] || {};
const getNewZoomType = (type, zoomType = "out") => {
  const ganttZoomTypes = [ganttTypes.days, ganttTypes.weeks, ganttTypes.months];
  const index = ganttZoomTypes.indexOf(type);

  if (zoomType === "out") {
    return ganttZoomTypes[Math.min(index + 1, ganttZoomTypes.length - 1)];
  } else if (zoomType === "in") {
    return ganttZoomTypes[Math.max(index - 1, 0)];
  }

  return type;
};

const Gantt = ({ events = [], title, filterDates = {}, EventsSort, DateFilterMenu }) => {
  const classes = useStyles();
  const customScrollEl = useRef(null);
  const ganttWrapperEl = useRef(null);
  const [type, setType] = useState(ganttTypes.days);
  const prevType = usePrevious(type);
  const size = useWindowSize();
  const {
    defaultColWidth,
    zoomMax,
    minColWidth,
    setRoundFirstEvent,
    setRoundLastEvent,
    colLabelDateFormat,
    labelShortDateFormat,
    labelDateFormat,
    labelIsThisYearDateFormat,
    getEventColDivisor,
    getDiffEvents,
    getDiffEvent,
    isFirstOfType,
    isNowOfType,
    getColsDate,
    getDiffLabelEvents,
    getLabelDate,
    getLabelsCols,
  } = ganttConfig[type];
  const svgGanttId = "svg-gantt";
  const ganttWrapperId = "gantt-wrapper";
  const firsEvent = getFirsEvent(events);
  const firsEventStart = getFirstDate([firsEvent.startDate, ...(firsEvent.alternativeStartDates || [])]);
  const lastEvent = getLastEvent(events);
  const lastEventEnd = getFirstDate([lastEvent.endDate, ...(lastEvent.alternativeEndDates || [])], true);
  const startFilter = filterDates.startDate;
  const endFilter = filterDates.endDate;
  const startFilterDate = new Date(filterDates.startDate);
  const endFilterDate = new Date(filterDates.endDate);
  const startEventDate = setRoundFirstEvent(
    parseISO(new Date(startFilter ? startFilterDate : firsEventStart || Date.now()).toISOString()),
  );
  const lastEventDate = setRoundLastEvent(
    parseISO(new Date(endFilter ? endFilterDate : lastEventEnd || Date.now()).toISOString()),
  );

  const diffEvents = getDiffEvents(lastEventDate, startEventDate) || 1;
  const labels = getLabelsCols(startEventDate, getDiffLabelEvents(lastEventDate, startEventDate) || 1);

  const primaryColor = "#7fb9ab";
  const colEventColor = "#aeebdc";
  const textColor = "#07203b";
  const strokeColor = "#e5e5e5";
  const darkStrokeColor = "#b2b2b2";

  const [colWidth, setColWidth] = useState(defaultColWidth);
  const colHeight = 23;
  const colRadius = 12;
  const colOffset = 26;

  const strokeWidth = 1;
  const monthHeight = 44;
  const firstEventOffset = monthHeight + 54;

  const svgWidth = colWidth * (diffEvents || 1);

  const svgHeight = events.length * (colHeight + colOffset) + firstEventOffset;

  const zoomStep = colWidth / 10;
  const scrollClickStep = 10;

  const getWrapperSize = useCallback((type = "width") => ganttWrapperEl.current?.getBoundingClientRect()?.[type] ?? 0, [
    ganttWrapperEl,
  ]);

  const zoomIn = useCallback(
    (step = zoomStep) => {
      const newType = getNewZoomType(type, "in");

      if (defaultColWidth * zoomMax > colWidth) {
        setColWidth(width => Math.min(defaultColWidth * zoomMax, width + step));
      } else if (newType !== type) {
        setColWidth(ganttConfig[newType].minColWidth);
        setType(newType);
      }
    },
    [type, defaultColWidth, zoomMax, zoomStep, colWidth, setColWidth],
  );
  const zoomOut = useCallback(
    (step = zoomStep) => {
      const newType = getNewZoomType(type, "out");

      if (colWidth > minColWidth && Math.floor(svgWidth) > Math.floor(getWrapperSize("width"))) {
        setColWidth(width => Math.max(minColWidth, width - step));
      } else if (newType !== type) {
        setColWidth(ganttConfig[newType].minColWidth);
        setType(newType);
      }
    },
    [type, colWidth, minColWidth, setColWidth, svgWidth, getWrapperSize, zoomStep],
  );

  const isShowGantt = firsEvent.startDate != null && lastEvent.endDate != null && diffEvents !== 0;

  const disabledIn = !isShowGantt || (type === ganttTypes.days && defaultColWidth * zoomMax <= colWidth);

  const disabledOut =
    !isShowGantt ||
    (type === ganttTypes.months &&
      (Math.floor(colWidth * diffEvents) <= Math.floor(getWrapperSize("width")) ||
        Math.floor(colWidth) <= minColWidth));

  useEffect(() => {
    const wrapperWidth = getWrapperSize("width");
    const wrapperColWidth = wrapperWidth / diffEvents;

    if (wrapperWidth > 0) {
      if (svgWidth < wrapperWidth) {
        setColWidth(Math.max(minColWidth, wrapperColWidth));
      } else if (type !== prevType && svgWidth > wrapperWidth) {
        setColWidth(defaultColWidth);
      }
    }
  }, [type, prevType, getWrapperSize, diffEvents, defaultColWidth, svgWidth, minColWidth]);

  useEffect(() => {
    if (customScrollEl.current && ganttWrapperEl.current) {
      const scrollWrapperEl = customScrollEl.current;
      const ganttEl = ganttWrapperEl.current;
      const scrollEl = scrollWrapperEl.getElementsByClassName("scroll")[0];
      const scrollWrapperWidth = scrollWrapperEl?.getBoundingClientRect?.()?.width;

      const scrollLeft = ganttEl.scrollLeft;
      const scrollWidth = ganttEl.scrollWidth;
      const clientWidth = ganttEl.clientWidth;

      scrollEl.style.width = `${scrollWrapperWidth * (clientWidth / scrollWidth)}px`;
      scrollEl.style.transform = `translateX(${scrollWrapperWidth * (scrollLeft / scrollWidth)}px)`;
    }
  }, [type, colWidth, customScrollEl, ganttWrapperEl, size.width, isShowGantt]);

  return (
    <div className={classes.wrapper}>
      <Grid container spacing={5} className={classes.root}>
        <Grid item xs={4} xl={3} className={classes.eventsList}>
          <Box color="text.primary">
            <Typography className={classes.title} variant="h6">
              {title}
            </Typography>
          </Box>
          {isShowGantt && (
            <div>
              <Box display="flex" justifyContent="space-between" mb={1.2}>
                <div>{EventsSort && <EventsSort />}</div>
                <Box mt={1.75}>
                  <IconsInfoDialog />
                </Box>
              </Box>
              <GanttEventsList events={events} />
            </div>
          )}
        </Grid>

        <Grid item xs={8} xl={9} className={classes.ganntRoot}>
          <div className={classes.topBar}>
            <Box className={classes.dateRangeFormControl}>
              <DateFilterMenu />
            </Box>
            <ScrollBar
              disabled={!isShowGantt}
              customScrollElRef={customScrollEl}
              scrollLeft={() => {
                if (!isShowGantt) return;
                const ganttEl = ganttWrapperEl.current;
                ganttEl.scrollBy(-((ganttEl.scrollWidth - ganttEl.clientWidth) / scrollClickStep), 0);
              }}
              scrollRight={() => {
                if (!isShowGantt) return;
                const ganttEl = ganttWrapperEl.current;
                ganttEl.scrollBy((ganttEl.scrollWidth - ganttEl.clientWidth) / scrollClickStep, 0);
              }}
              onMove={event => {
                if (!isShowGantt) return;
                event.preventDefault();
                disableScrollBar = true;
                const ganttEl = ganttWrapperEl.current;
                const scrollWrapperEl = customScrollEl.current;
                const scrollEl = scrollWrapperEl.getElementsByClassName("scroll")[0];
                const scrollWrapperWidth = scrollWrapperEl.getBoundingClientRect().width;
                const ganttAllWidth = ganttEl.scrollWidth;
                const shiftX = event.clientX - scrollEl.getBoundingClientRect().left;
                scrollEl.style.cursor = "grabbing";

                function onMouseMove(event) {
                  let newScrollLeft = event.clientX - shiftX - scrollWrapperEl.getBoundingClientRect().left;
                  const rightEdge = scrollWrapperEl.offsetWidth - scrollEl.offsetWidth;

                  if (newScrollLeft < 0) {
                    newScrollLeft = 0;
                  }

                  if (newScrollLeft > rightEdge) {
                    newScrollLeft = rightEdge;
                  }

                  scrollEl.style.transform = `translateX(${newScrollLeft}px)`;
                  ganttEl.scrollTo(ganttAllWidth * (newScrollLeft / scrollWrapperWidth), 0);
                }

                function onMouseUp() {
                  disableScrollBar = false;
                  scrollEl.style.cursor = "grab";
                  document.removeEventListener("mouseup", onMouseUp);
                  document.removeEventListener("mousemove", onMouseMove);
                }

                document.addEventListener("mouseup", onMouseUp);
                document.addEventListener("mousemove", onMouseMove);
              }}
            />
            <ZoomButtons zoomIn={zoomIn} zoomOut={zoomOut} disabledIn={disabledIn} disabledOut={disabledOut} />
          </div>

          {isShowGantt && (
            <div
              id={ganttWrapperId}
              ref={ganttWrapperEl}
              className={classes.ganttWrapper}
              onScroll={event => {
                if (!disableScrollBar) {
                  const scrollWrapperEl = customScrollEl.current;
                  const scrollEl = scrollWrapperEl.getElementsByClassName("scroll")[0];
                  const scrollWrapperWidth = scrollWrapperEl.getBoundingClientRect().width;
                  const scrollLeft = event.target.scrollLeft;
                  const scrollWidth = event.target.scrollWidth;

                  scrollEl.style.transform = `translateX(${scrollWrapperWidth * (scrollLeft / scrollWidth)}px)`;
                }
              }}
            >
              <svg
                id={svgGanttId}
                width={svgWidth}
                height={svgHeight}
                // onWheel={event => {
                //   event.preventDefault();
                //   event.stopPropagation();
                //
                //   if (!ticking) {
                //     console.log(event.deltaY, "event.deltaY");
                //
                //     if (event.deltaY > 0) {
                //       window.requestAnimationFrame(() => {
                //         zoomOut();
                //         ticking = false;
                //       });
                //     } else if (event.deltaY < 0) {
                //       window.requestAnimationFrame(() => {
                //         zoomIn();
                //         ticking = false;
                //       });
                //     }
                //   }
                // }}
              >
                <g id="labels">
                  {labels.map(([date, cols], index) => {
                    const startOffset =
                      colWidth * labels.slice(0, index).reduce((acc, [, num]) => acc + num, 0) + (index > 0 ? 0 : 1);
                    const labelDate = new Date(getLabelDate(date));
                    const fullLabelFormat = isThisYear(labelDate) ? labelIsThisYearDateFormat : labelDateFormat;
                    return (
                      <Fragment key={date}>
                        <rect
                          x={startOffset}
                          y="0"
                          width={colWidth * cols + (index > 0 ? 0 : -1)}
                          height={monthHeight}
                          strokeWidth={strokeWidth}
                          stroke={strokeColor}
                          fill="transparent"
                        />
                        <text
                          x={startOffset}
                          y={0}
                          dy={monthHeight / 2 + 6}
                          dx={(colWidth * cols) / 2}
                          textAnchor="middle"
                          fill={textColor}
                        >
                          {formatDate(labelDate, cols > 1 ? fullLabelFormat : labelShortDateFormat).toUpperCase()}
                        </text>
                      </Fragment>
                    );
                  })}
                </g>

                <g id="cols">
                  {Array.from(Array(diffEvents)).map((_, diff) => {
                    const date = getColsDate(startEventDate, diff);
                    const prevDate = getColsDate(startEventDate, diff - 1);
                    const lineX = colWidth * diff + (diff === 0 ? 1 : 0);

                    return (
                      <Fragment key={lineX}>
                        <line
                          x1={lineX}
                          y1={monthHeight}
                          x2={lineX}
                          y2={svgHeight}
                          strokeWidth={strokeWidth}
                          stroke={diff > 0 && isFirstOfType(date, prevDate) ? darkStrokeColor : strokeColor}
                        />
                        <text
                          x={colWidth * diff}
                          y={colHeight + monthHeight}
                          dx={colWidth / 2}
                          textAnchor="middle"
                          fill={isNowOfType(date) ? primaryColor : textColor}
                        >
                          {formatDate(date, colLabelDateFormat)}
                        </text>
                      </Fragment>
                    );
                  })}
                  <line
                    key="line"
                    x1={svgWidth}
                    y1={monthHeight}
                    x2={svgWidth}
                    y2={svgHeight}
                    strokeWidth={strokeWidth}
                    stroke={strokeColor}
                  />
                </g>

                <g id="events">
                  {events.map(
                    ({ id, name, startDate, endDate, alternativeStartDates = [], alternativeEndDates = [] }, index) => {
                      if (!(startDate && endDate)) return null;
                      const dates = [
                        [startDate, endDate],
                        ...alternativeStartDates.map((date, index) => [date, alternativeEndDates[index]]),
                      ];

                      return (
                        <>
                          {dates.map(([start, end]) => (
                            <rect
                              key={id + start + end}
                              x={
                                (colWidth / getEventColDivisor(parseISO(start))) *
                                (getDiffEvent(parseISO(start), setHours(startEventDate, 0)) || 1)
                              }
                              y={(colHeight + colOffset) * index + firstEventOffset}
                              rx={colRadius}
                              width={
                                (colWidth / getEventColDivisor(parseISO(start))) *
                                (getDiffEvent(parseISO(end), parseISO(start)) || 1)
                              }
                              height={colHeight}
                              fill={colEventColor}
                              data-id={id}
                              data-name={name}
                              data-start-date={parseISO(start)}
                              data-end-date={parseISO(end)}
                            />
                          ))}
                        </>
                      );
                    },
                  )}
                </g>
              </svg>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

Gantt.propTypes = {
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  events: PropTypes.arrayOf(eventPropTypes),
  filterDates: PropTypes.shape({
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }),
  EventsSort: PropTypes.any,
  DateFilterMenu: PropTypes.any,
};

export default Gantt;
