export const googleMapsApiKey = "AIzaSyDVfJCPWZwKdqw4ZQiuk__-v1GLT7LLeH0";

export const locationsCoords = {
  copenhagen: {
    lat: 55.6713108,
    lng: 12.5588047,
  },
  noerrebro: {
    lat: 55.6965067,
    lng: 12.5432494,
  },
  greaterCopenhagen: {
    lat: 55.6995301,
    lng: 12.577124,
  },
  valby: {
    lat: 55.6599508,
    lng: 12.4882626,
  },
  amager: {
    lat: 55.6185057,
    lng: 12.5856182,
  },
  indreby: {
    lat: 55.6832774,
    lng: 12.5755644,
  },
  frederiksberg: {
    lat: 55.6822053,
    lng: 12.5160286,
  },
  oesterbro: {
    lat: 55.7093466,
    lng: 12.5756935,
  },
  vesterbro: {
    lat: 55.6624555,
    lng: 12.5395401,
  },
  sydhavnen: {
    lat: 55.6465112,
    lng: 12.5306026,
  },
  refshaleoen: {
    lat: 55.693211,
    lng: 12.6142415,
  },
  nordhavn: {
    lat: 55.7158873,
    lng: 12.5977678,
  },
};
