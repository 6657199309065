import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { ageGroupMap, audienceMap, eventPropTypes, eventTypeMap, pathRoutes, priceMap } from "../constants";
import IconWrapper from "./IconWrapper";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import StarIcon from "./icons/StarIcon";
import AudienceIcon from "./icons/AudienceIcon";
import ArrowUpIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownIcon from "@material-ui/icons/ArrowDownward";
import defaultImage from "../assets/default-image.png";

const useStyles = makeStyles(theme => ({
  article: {
    fontFamily: theme.typography.fontFamily,
    width: 340,
    height: 129,
    display: "flex",
    backgroundColor: "white",
    borderBottom: "1px solid #333",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  thumb: {
    width: 128,
    height: 128,
  },
  img: {
    display: "block",
    objectFit: "cover",
  },
  title: {
    width: 195,
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginTop: theme.spacing(-0.37),
    paddingBottom: 29,
    cursor: "pointer",
  },
  articleBoxContent: {
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexShrink: "0",
    padding: "10px 10px 10px 7px",
  },
  articleBoxContentBottom: {
    display: "flex",
    justifyContent: "space-between",
  },
  textWithIcon: {
    display: "flex",
    alignItems: "center",
  },
}));

const EventsMapCard = ({ event = {}, isFirst = false, isLast = false }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { id, type = "", name = "", score = "-", price = "", ageGroup = "", audience = "", imageData = {} } = event;

  return (
    <article className={classes.article}>
      <div>
        <div className={classes.thumb}>
          <img className={classes.img} src={imageData.url || defaultImage} alt="Event" width="128" height="128" />
        </div>
      </div>
      <div className={classes.articleBoxContent}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1" component="h3">
            {t(eventTypeMap[type])}
          </Typography>
          {!isFirst && <ArrowUpIcon fontSize="small" color="secondary" />}
        </Box>
        <Typography variant="subtitle1" component="h2" className={classes.title}>
          <strong>
            <Link href={`${pathRoutes.event}/${id}`}>{name}</Link>
          </strong>
        </Typography>
        <div className={classes.textWithIcon}>
          <Box mr={0.8}>
            <IconWrapper>
              <StarIcon width="18" height="18" fill={theme.palette?.secondary?.main} />
            </IconWrapper>
          </Box>
          <Typography variant="body1">{score || 0}</Typography>
        </div>
        <div className={classes.textWithIcon}>
          <Box mr={1}>
            <IconWrapper>
              <AudienceIcon fill={theme.palette?.secondary?.main} />
            </IconWrapper>
          </Box>
          <Typography variant="body1">
            <strong>{t(audienceMap[audience]) || "-"}</strong>
          </Typography>
        </div>
        <div className={classes.articleBoxContentBottom}>
          <Typography variant="body1">{ageGroupMap[ageGroup]}</Typography>
          {!isLast && <ArrowDownIcon fontSize="small" color="secondary" />}
          <Typography variant="body1">
            <strong>{t(priceMap[price])}</strong>
          </Typography>
        </div>
      </div>
    </article>
  );
};

EventsMapCard.propTypes = {
  event: eventPropTypes,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
};

export default EventsMapCard;
