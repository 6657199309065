import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCvBCB9zMAPBehODJM4KljwZNPhaD9dCn4",
  authDomain: "wonderfulcph-b0851.firebaseapp.com",
  databaseURL: "https://wonderfulcph-b0851.firebaseio.com",
  projectId: "wonderfulcph-b0851",
  storageBucket: "wonderfulcph-b0851.appspot.com",
  messagingSenderId: "1055975751677",
  appId: "1:1055975751677:web:cfcc6c4cd087dc3ae92394",
  measurementId: "G-VJX8WT8GF6",
};

firebase.initializeApp(firebaseConfig);

if (!process.env.NODE_ENV.includes("dev")) {
  firebase.analytics();
}

export const auth = firebase.auth();

export const firestore = firebase.firestore();

export const storage = firebase.storage();

export const getCurrentUser = () => auth?.currentUser;

export default firebase;
