import React from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuth } from "../Provider/AuthProvider";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authUserErrorCodes, authPasswordErrorCodes } from "../constants";
import Typography from "@material-ui/core/Typography";

const SignInForm = () => {
  const defaultValues = {
    email: "",
    password: "",
  };
  const { control, handleSubmit, reset, errors, setError, clearError } = useForm({ defaultValues });
  const { signIn } = useAuth();
  const { t } = useTranslation();

  const onSubmit = async ({ email, password }) => {
    try {
      Object.keys(defaultValues).forEach(key => clearError(key));

      await signIn(email, password);
    } catch (error) {
      if (authUserErrorCodes.includes(error.code)) {
        setError("email", "authError", error.message);
      } else if (authPasswordErrorCodes.includes(error.code)) {
        setError("email", "authError", error.message);
        setError("password", "authError", error.message);
      } else {
        setError("other", "authError", error.message);
        console.error(error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            as={TextField}
            name="email"
            type="email"
            autoComplete="username"
            control={control}
            rules={{ required: true }}
            fullWidth
            required
            label={t("E-mail")}
            defaultValue=""
            error={Boolean(errors.email)}
            helperText={errors.email?.message ?? ""}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            as={TextField}
            name="password"
            type="password"
            autoComplete="current-password"
            control={control}
            rules={{ required: true }}
            fullWidth
            required
            label={t("Password")}
            defaultValue=""
            error={Boolean(errors.password)}
            helperText={errors.password?.message ?? ""}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Button fullWidth type="submit" variant="contained" color="primary">
            {t("Login")}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button fullWidth type="submit" variant="contained" onClick={() => reset(defaultValues)}>
            {t("Clear")}
          </Button>
        </Grid>

        {errors.other && (
          <Grid item xs={12}>
            <Typography style={{ color: "red" }} variant="h6">
              {errors.other?.message}
            </Typography>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

SignInForm.propTypes = {
  onSubmitCb: PropTypes.func,
};

export default SignInForm;
