import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { audienceGanttMap, eventPropTypes, pathRoutes } from "../constants";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconWrapper from "./IconWrapper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import StarIcon from "./icons/StarIcon";
import AudienceIcon from "./icons/AudienceIcon";
import InternationalIcon from "./icons/InternationalIcon";
import NationalIcon from "./icons/NationalIcon";

const useStyles = makeStyles({
  root: {},
  item: {
    position: "relative",
    display: "flex",
    marginBottom: 4,
    backgroundColor: "#f8f8f8",
    paddingTop: 8,
    paddingRight: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: "60%",
      zIndex: 1,
      width: 2,
      marginLeft: -1,
      backgroundColor: "white",
    },
  },
  itemEl: {
    marginRight: 2,
    "&:not(:first-child)": {
      paddingLeft: 8,
    },
  },
  itemTextEl: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    flexBasis: "calc(60% - 2px)",
  },
  icons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexBasis: "calc(40% - 2px)",
  },
  iconsBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "& > div:not(:first-child)": {
      marginLeft: 5,
    },
  },
});

const GanttEventsList = ({ className, events = [] }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <List className={`${classes.root} ${className}`} aria-label="Events list">
      {events.map(({ id, name, score = "-", audience = "-", international = false }) => (
        <ListItem key={id} className={classes.item}>
          <ListItemText
            primary={<Link href={`${pathRoutes.event}/${id}`}>{name}</Link>}
            className={clsx(classes.itemEl, classes.itemTextEl)}
          />
          <div className={clsx(classes.itemEl, classes.icons)}>
            <div className={classes.iconsBox}>
              <IconWrapper>
                <StarIcon number={score || 0} fill={theme.palette.secondary.dark} />
              </IconWrapper>
              <Box display="flex" minWidth={78}>
                <IconWrapper>
                  <AudienceIcon fill={theme.palette.secondary.main} />
                </IconWrapper>
                <Typography variant="body1">
                  <strong>{audienceGanttMap[audience] || "-"}</strong>
                </Typography>
              </Box>
              <IconWrapper>
                {international ? <InternationalIcon fill={theme.palette.secondary.main} /> : <NationalIcon />}
              </IconWrapper>
            </div>
          </div>
        </ListItem>
      ))}
    </List>
  );
};

GanttEventsList.propTypes = {
  className: PropTypes.string,
  events: PropTypes.arrayOf(eventPropTypes),
};

export default GanttEventsList;
