import { transformFieldsToMap } from "../helpers";
import { ageGroupIcons, eventTypeIcons, othersIcons } from "./icons";

export const eventTypeFields = [
  { value: "other", label: "Andet", Icon: eventTypeIcons.other },
  { value: "festival", label: "Festival", Icon: eventTypeIcons.festival },
  { value: "folkfestival", label: "Folkefest", Icon: eventTypeIcons.folkfestival },
  { value: "concert", label: "Koncert", Icon: eventTypeIcons.concert },
  { value: "awards", label: "Prisuddeling", Icon: eventTypeIcons.awards },
  { value: "sport", label: "Sport", Icon: eventTypeIcons.sport },
  { value: "exhibition", label: "Udstilling", Icon: eventTypeIcons.exhibition },
];
export const eventTypeMap = transformFieldsToMap(eventTypeFields);

export const eventCategoryFields = [
  { value: "design/architecture", label: "Design & arkitektur" },
  { value: "film/photo", label: "Film & foto" },
  { value: "gastronomy", label: "Gastronomi" },
  { value: "history", label: "Historie" },
  { value: "art/culture", label: "Kunst & kultur" },
  { value: "literature", label: "Litteratur" },
  { value: "food/drink", label: "Mad & Drikke" },
  { value: "music/dance", label: "Musik & dans" },
  { value: "nature", label: "Natur" },
  { value: "theater", label: "Teater" },
  { value: "technology", label: "Teknologi" },
];
export const eventCategoryMap = transformFieldsToMap(eventCategoryFields);

export const ageGroupFields = [
  { value: "all", label: "Alle", Icon: ageGroupIcons.all },
  { value: "children13", label: "Børn (0-13 år)", Icon: ageGroupIcons.children13 },
  { value: "children", label: "Børn", Icon: ageGroupIcons.children },
  { value: "familiesWithChildren", label: "Børnefamilier" },
  { value: "teenager", label: "Unge", Icon: ageGroupIcons.teenager },
  { value: "teenager/adults", label: "Unge & Voksne", Icon: ageGroupIcons["teenager/adults"] },
  { value: "adults", label: "Voksne", Icon: ageGroupIcons.adults },
];
export const ageGroupMap = transformFieldsToMap(ageGroupFields);

export const locationsFields = [
  { value: "amager", label: "Amager" },
  { value: "frederiksberg", label: "Frederiksberg" },
  { value: "greaterCopenhagen", label: "Greater Copenhagen" },
  { value: "indreby", label: "Indre by" },
  { value: "copenhagen", label: "København" },
  { value: "nordhavn", label: "Nordhavn" },
  { value: "noerrebro", label: "Nørrebro" },
  { value: "oesterbro", label: "Østerbro" },
  { value: "refshaleoen", label: "Refshaleøen" },
  { value: "sydhavnen", label: "Sydhavn" },
  { value: "valby", label: "Valby" },
  { value: "vesterbro", label: "Vesterbro" },
];
export const locationsMap = transformFieldsToMap(locationsFields);

export const priceFields = [
  { value: "0", label: "Gratis" },
  { value: "149", label: "kr. 1 - 150" },
  { value: "299", label: "kr. 150 - 300" },
  { value: "499", label: "kr. 300 - 500" },
  { value: "799", label: "kr. 500 - 800" },
  { value: "1199", label: "kr. 800 - 1200" },
  { value: "1200", label: "Over kr. 1200" },
];
export const priceMap = transformFieldsToMap(priceFields);

export const participantsFields = [
  { value: "499", label: "0-500" },
  { value: "999", label: "500-1.000" },
  { value: "1999", label: "1.000-2.000" },
  { value: "4999", label: "2.000-5.000" },
  { value: "9999", label: "5.000-10.000" },
  { value: "10000", label: "Over 10.000" },
  { value: "20000", label: "Over 20.000" },
  { value: "50000", label: "Over 50.000" },
  { value: "100000", label: "Over 100.000" },
  { value: "200000", label: "Over 200.000" },
  { value: "500000", label: "Over 500.000" },
  { value: "800000", label: "Over 800.000" },
];
export const participantsMap = transformFieldsToMap(participantsFields);

export const audienceFields = [
  { value: "499", label: "0-500" },
  { value: "999", label: "500-1.000" },
  { value: "1999", label: "1.000-2.000" },
  { value: "4999", label: "2.000-5.000" },
  { value: "9999", label: "5.000-10.000" },
  { value: "10000", label: "Over 10.000" },
  { value: "20000", label: "Over 20.000" },
  { value: "50000", label: "Over 50.000" },
  { value: "100000", label: "Over 100.000" },
  { value: "200000", label: "Over 200.000" },
  { value: "500000", label: "Over 500.000" },
  { value: "800000", label: "Over 800.000" },
];

export const audienceGanttMap = {
  499: "0-0.5k",
  999: "0.5k-1k",
  1999: "1k-2k",
  4999: "2k-5k",
  9999: "5k-10k",
  10000: "10k",
  20000: "20k",
  50000: "50k",
  100000: "100k",
  200000: "200k",
  500000: "500k",
};
export const audienceMap = transformFieldsToMap(audienceFields);

export const strategicScoresFields = [
  { value: "localhood", label: "Localhood (true & authentic)" },
  { value: "greaterCopenhagenExpansion", label: "Greater Copenhagen expansion" },
  { value: "shareable/extraordinary", label: "Shareable & Extraordinary" },
  { value: "cph", label: "CPH with ”edge”" },
  { value: "attractsBusiness", label: "Attracts business and innovation" },
  { value: "inspires", label: "Inspires to lifelong learning" },
  { value: "supportsGoodLife", label: "Supports the ”good life”" },
  { value: "trendsSports", label: "Ajour with current trends of sports" },
  { value: "socialResponsibility", label: "Communicates initiatives within social responsibility" },
  { value: "climateInitiatives", label: "Communicates about climate initiatives" },
];
export const strategicScoresMap = transformFieldsToMap(strategicScoresFields);

export const tagsFields = [
  { value: "art/design", label: "Art & Design" },
  { value: "gastronomy", label: "Gastronomy" },
  { value: "greenLivability", label: "Green livability" },
  { value: "modernArchitecture", label: "Modern Architecture" },
  { value: "monarchy/history", label: "Monarchy & History" },
];
export const tagsMap = transformFieldsToMap(tagsFields);

export const eventInfluenceFields = [
  { value: "moves", label: "Moves through the city" },
  { value: "troubles", label: "Trouble for city" },
  { value: "excitement", label: "Excitement for city" },
];

export const eventInfluenceMap = transformFieldsToMap(eventInfluenceFields);

export const eventMoreFields = [
  { value: "indoors", label: "Indoors" },
  { value: "outdoor", label: "Outdoors" },
  { value: "international", label: "International", Icon: othersIcons.international },
  { value: "recurring", label: "Recurring", Icon: othersIcons.recurring },
  { value: "subsidiary", label: "Subsidiary from the City" },
  { value: "primaryFocus", label: "btc", type: "string" },
  ...eventInfluenceFields,
];
export const eventMoreMap = transformFieldsToMap(eventMoreFields);
