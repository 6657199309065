import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import RoundButton from "./RoundButton";

const styles = theme => ({
  buttons: ({ topSpacing = 4 }) => ({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: theme.spacing(topSpacing),
  }),
});

const FiltersMenuButtons = ({ classes, onClear, onSave, onClearDisabled = false, onSaveDisabled = false, t }) => (
  <div className={classes.buttons}>
    {onClear && (
      <RoundButton onClick={onClear} disabled={onClearDisabled}>
        {t("Clear")}
      </RoundButton>
    )}
    {onSave && (
      <RoundButton variant="contained" color="primary" onClick={onSave} disabled={onSaveDisabled}>
        {t("Save")}
      </RoundButton>
    )}
  </div>
);

FiltersMenuButtons.propTypes = {
  onClear: PropTypes.func,
  onSave: PropTypes.func,
  topSpacing: PropTypes.number,
  onClearDisabled: PropTypes.bool,
  onSaveDisabled: PropTypes.bool,
  // From HOC
  classes: PropTypes.shape({
    buttons: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(FiltersMenuButtons));
