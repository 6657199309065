import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";

const PageLoader = ({ isLoading = true }) => (
  <>
    {isLoading && (
      <div style={{ marginTop: "15vh", marginBottom: "15vh" }}>
        <LinearProgress />
      </div>
    )}
  </>
);

PageLoader.propTypes = {
  isLoading: PropTypes.bool,
};

export default PageLoader;
