import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { eventPropTypes, adminEventsListDateFormat } from "../constants";
import { formatDate } from "../helpers";
import DeleteAlertButton from "./DeleteAlertButton";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const AdminEventsList = ({ isSuperAdmin, events = [], editEvent, deleteEvent, t }) => (
  <>
    <TableContainer component={Paper}>
      <Table aria-label="Admin Events table">
        <TableHead>
          <TableRow>
            <TableCell>{t("Name")}</TableCell>
            <TableCell>{t("Type")}</TableCell>
            <TableCell>{t("Category")}</TableCell>
            <TableCell>{t("Date")}</TableCell>
            {isSuperAdmin && (
              <TableCell align="center" style={{ width: 130 }}>
                {t("Actions")}
              </TableCell>
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {events.map(({ id, name = "", type = "", category = "", startDate, endDate }) => (
            <TableRow key={id}>
              <TableCell>{name}</TableCell>
              <TableCell>{type}</TableCell>
              <TableCell>{category}</TableCell>
              <TableCell>
                {startDate ? formatDate(new Date(startDate), adminEventsListDateFormat) : ""} -{" "}
                {endDate ? formatDate(new Date(endDate), adminEventsListDateFormat) : ""}
              </TableCell>
              {isSuperAdmin && (
                <TableCell align="center" style={{ width: 130 }}>
                  {editEvent && (
                    <IconButton aria-label="Edit" onClick={() => editEvent(id)}>
                      <EditIcon />
                    </IconButton>
                  )}
                  {deleteEvent && (
                    <DeleteAlertButton
                      buttonType="icon"
                      alertText={`${t("Delete")} ${t("event")}?`}
                      alertParams={{ descr: `${t("Event")} ${t("name")}: ${name}` }}
                      onClick={() => {
                        deleteEvent(id);
                      }}
                    >
                      <DeleteForeverIcon />
                    </DeleteAlertButton>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

AdminEventsList.propTypes = {
  isSuperAdmin: PropTypes.bool,
  events: PropTypes.arrayOf(eventPropTypes),
  editEvent: PropTypes.func,
  deleteEvent: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(AdminEventsList);
