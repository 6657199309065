import isAfter from "date-fns/isAfter";
import isSameDay from "date-fns/isSameDay";

const getDateWithAltDates = (
  startDateDefault,
  endDateDefault,
  alternativeStartDates = [],
  alternativeEndDates = [],
) => {
  const dates = [
    [startDateDefault, endDateDefault],
    ...alternativeStartDates.map((date, index) => [date, alternativeEndDates[index]]),
  ].sort(([a], [b]) => new Date(a) - new Date(b));
  const altEndDates = dates.filter(
    ([, endDate]) => isAfter(new Date(endDate), new Date()) || isSameDay(new Date(endDate), new Date()),
  )[0];
  const startDate = altEndDates ? altEndDates[0] : startDateDefault;
  const endDate = altEndDates ? altEndDates[1] : endDateDefault;

  return [new Date(startDate), new Date(endDate)];
};

export default getDateWithAltDates;
