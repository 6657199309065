import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { strategicScoresFields } from "../constants";
import { isEmptyObject } from "../helpers";
import FilterButton from "./FilterButton";
import FiltersMenuButtons from "./FiltersMenuButtons";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 330,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    outline: "none",
    "& strong": {
      fontSize: "1rem",
    },
  },
}));

const CustomSlider = withStyles({
  root: {
    height: 2,
  },
  thumb: {
    height: 24,
    width: 24,
    marginTop: -12,
    marginLeft: -12,
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 1,
    backgroundColor: "#999999",
  },
  mark: {
    backgroundColor: "#999999",
    height: 8,
    width: 2,
    marginTop: -3,
    opacity: 1,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
})(Slider);

const ScoreFilterMenu = ({ buttonText = "", activeFields = {}, onSave }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState([0, 10]);
  const scores = Object.keys(activeFields).map(score => parseInt("null" === score ? 0 : score || 0, 10));
  const [firstLabel, lastLabel] = [Math.min(...scores), Math.max(...scores)];

  const handleOpen = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleChange = (name, newState) => setState(newState);

  useEffect(() => {
    if (!isEmptyObject(activeFields)) {
      const scores = Object.keys(activeFields).map(score => parseInt("null" === score ? 0 : score || 0, 10));
      setState([Math.min(...scores), Math.max(...scores)]);
    }
  }, [activeFields]);

  return (
    <div>
      <FilterButton
        active={String(!isEmptyObject(activeFields))}
        variant="contained"
        aria-controls="score-filter-menu"
        aria-haspopup="true"
        onClick={handleOpen}
      >
        {buttonText}
        {!isEmptyObject(activeFields) ? ` • ${firstLabel}${firstLabel === lastLabel ? "" : ` - ${lastLabel}`}` : ""}
      </FilterButton>
      <Menu
        variant="selectedMenu"
        id="score-filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ListItem className={classes.container}>
          <div>
            <Typography id="discrete-slider-filter" paragraph>
              {t("Strategic Score are set from 0-10")}
            </Typography>
            <Typography align="center">
              <strong>
                {state[0]} {t("til")} {state[1]}
              </strong>
            </Typography>
            <CustomSlider
              color="secondary"
              aria-labelledby="discrete-slider-filter"
              valueLabelDisplay="auto"
              value={state}
              step={1}
              marks
              min={0}
              max={10}
              onChange={handleChange}
            />
          </div>
          <FiltersMenuButtons
            onClear={() => handleChange("", [0, 10])}
            onSave={() => {
              onSave(state);
              handleClose();
            }}
          />
          <Box mt={3}>
            <Typography gutterBottom>{t("ScoreFilterMenuDescr1")}</Typography>
            <Typography paragraph>{t("ScoreFilterMenuDescr2")}</Typography>
            <Typography>
              <small>
                {t("Strategiparametre")}
                <br />
                {strategicScoresFields.map(({ value, label }) => (
                  <Fragment key={value}>
                    <span>{t(label)}</span>
                    <br />
                  </Fragment>
                ))}
              </small>
            </Typography>
          </Box>
        </ListItem>
      </Menu>
    </div>
  );
};

ScoreFilterMenu.propTypes = {
  buttonText: PropTypes.string.isRequired,
  activeFields: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ScoreFilterMenu;
