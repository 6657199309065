import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { imageDataPropTypes } from "../constants";
import { makeStyles } from "@material-ui/core/styles";
import Cropper from "react-easy-crop";
import { getCroppedImg, debounce, throttle } from "../helpers";
import FileUpload from "./FileUpload";
import Fab from "@material-ui/core/Fab";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(theme => ({
  imageUploadContainer: {
    position: "relative",
    width: "100%",
    paddingBottom: "100%",
    overflow: "hidden",
  },
  clearImage: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: "1",
    "& svg": {
      fill: "white",
    },
  },
}));

let isChangedImage = false;

const ImageCropperUploader = ({ imageData = {}, onSave }) => {
  const classes = useStyles();
  const [sourceImageUrl, setSourceImageUrl] = useState(imageData.originalUrl);
  // eslint-disable-next-line no-unused-vars
  const [croppedImage, setCroppedImage] = useState(imageData.url);
  const [crop, setCrop] = useState(imageData.crop || { x: 0, y: 0 });
  const [zoom, setZoom] = useState(imageData.zoom || 2);
  const [rotation, setRotation] = useState(imageData.rotation || 0);

  const saveImage = useCallback(
    throttle(({ croppedImage }) => {
      onSave({
        sourceImageUrl: imageData.originalUrl !== sourceImageUrl ? sourceImageUrl : null,
        croppedImage,
        imageData: { crop, zoom, rotation, fileType: croppedImage.type },
      });
      isChangedImage = false;
    }, 1000),
    [sourceImageUrl, crop, zoom, rotation, onSave],
  );

  useEffect(() => {
    if (imageData.url) setCroppedImage(imageData.url);
  }, [imageData.url, setCroppedImage]);

  useEffect(() => {
    if (imageData.originalUrl) setSourceImageUrl(imageData.originalUrl);
  }, [imageData.originalUrl, setSourceImageUrl]);

  useEffect(() => {
    if (imageData.crop != null) setCrop(imageData.crop);
  }, [imageData.crop, setCrop]);

  useEffect(() => {
    if (imageData.zoom != null) setZoom(imageData.zoom);
  }, [imageData.zoom, setZoom]);

  useEffect(() => {
    if (imageData.rotation != null) setRotation(imageData.rotation);
  }, [imageData.rotation, setRotation]);

  const onCropComplete = useCallback(
    debounce(async (croppedArea, croppedAreaPixels) => {
      if (sourceImageUrl) {
        const croppedImage = await getCroppedImg(sourceImageUrl, croppedAreaPixels, rotation, true);
        setCroppedImage(croppedImage);

        if (isChangedImage) {
          saveImage({ sourceImageUrl, croppedImage });
        }
      }
    }, 300),
    [rotation, sourceImageUrl, saveImage],
  );

  return (
    <div>
      <div className={classes.imageUploadContainer}>
        <FileUpload
          isShowDNDArea={!sourceImageUrl}
          onUpload={file => {
            setSourceImageUrl(URL.createObjectURL(file));
            isChangedImage = true;
          }}
        >
          <Cropper
            image={sourceImageUrl}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            onInteractionEnd={() => {
              isChangedImage = true;
            }}
          />
        </FileUpload>
        {sourceImageUrl && (
          <Fab
            className={classes.clearImage}
            size="small"
            color="secondary"
            onClick={() => {
              setSourceImageUrl(null);
              setCroppedImage(null);
              onSave({});
            }}
          >
            <DeleteIcon color="inherit" />
          </Fab>
        )}
      </div>
      {/*<img src={croppedImage} alt="" width={300} height={300} />*/}
      {/*<img src={croppedImage} alt="" width={150} height={150} />*/}
    </div>
  );
};

ImageCropperUploader.propTypes = {
  imageData: imageDataPropTypes,
  onSave: PropTypes.func.isRequired,
};

export default ImageCropperUploader;
