import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import isSameDay from "date-fns/isSameDay";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import { useStyles as dayStyles } from "@material-ui/pickers/views/Calendar/Day";
import FiltersMenuButtons from "./FiltersMenuButtons";

const useStyles = makeStyles(theme => ({
  root: {},
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 40,
    height: 40,
    margin: "2px 0",
    borderRadius: "0",
    transition: "border-radius 0.1s ease",
    "&:hover": {
      borderRadius: "0",
      backgroundColor: theme.palette.secondary.main,
    },
  },
  daySelected: {
    backgroundColor: "rgba(109, 187, 171, 0.16)",
    borderRadius: "0",
  },
  activeDay: {
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.common.white,
    },
  },
  firstDay: {
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: "0",
      backgroundColor: "rgba(109, 187, 171, 0.16)",
      transition: "all 0.1s ease",
      borderRadius: "50% 0 0 50%",
    },
    "& .MuiIconButton-label": {
      zIndex: "1",
    },
  },
  endDay: {
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: "0",
      backgroundColor: "rgba(109, 187, 171, 0.16)",
      transition: "all 0.1s ease",
      borderRadius: "0 50% 50% 0",
    },
    "& .MuiIconButton-label": {
      zIndex: "1",
    },
  },
}));

function DateRangePicker({ value = [], onSave, ...props }) {
  const baseClasses = dayStyles();
  const classes = useStyles();
  const [startDay, setStartDay] = useState(value?.[0]);
  const [endDay, setEndDay] = useState(value?.[1]);
  const [hoverDay, setHover] = useState(undefined);

  const min = Math.min(startDay, endDay || hoverDay);
  const max = Math.max(startDay, endDay || hoverDay);

  const renderDay = (day, selectedDate, dayInCurrentMonth, dayComponent) =>
    React.cloneElement(dayComponent, {
      onClick: e => {
        e.stopPropagation();
        if (!startDay) {
          setStartDay(day);
        } else if (!endDay) {
          setEndDay(day);
        } else {
          setStartDay(day);
          setEndDay(undefined);
        }
      },
      onMouseEnter: () => setHover(day),
      className: clsx(classes.day, baseClasses.day, {
        [baseClasses.hidden]: dayComponent.props.hidden,
        [baseClasses.current]: dayComponent.props.current,
        [baseClasses.dayDisabled]: dayComponent.props.disabled,
        [classes.daySelected]: day >= min && day <= max,
        [classes.activeDay]: isSameDay(day, min) || isSameDay(day, max),
        [classes.firstDay]: !isSameDay(day, max) && isSameDay(day, min),
        [classes.endDay]: !isSameDay(day, min) && isSameDay(day, max),
      }),
    });

  return (
    <>
      <DatePicker
        {...props}
        className={classes.root}
        disableToolbar
        variant="static"
        value={startDay}
        renderDay={renderDay}
        onClose={() => onSave([startDay, endDay].sort())}
        onChange={() => onSave([startDay, endDay].sort())}
      />
      <FiltersMenuButtons
        topSpacing={2}
        onClear={() => {
          setStartDay(undefined);
          setEndDay(undefined);
          setHover(undefined);
        }}
        onSave={() =>
          onSave(
            [
              startDay && startDay.toString() !== "Invalid Date" && startOfDay(new Date(startDay)).toISOString(),
              endDay && endDay.toString() !== "Invalid Date" && endOfDay(new Date(endDay)).toISOString(),
            ].sort(),
          )
        }
        onSaveDisabled={(startDay && !endDay) || (!startDay && endDay)}
      />
    </>
  );
}

DateRangePicker.propTypes = {
  value: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  onSave: PropTypes.func.isRequired,
};

export default DateRangePicker;
