import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const ErrorText = ({ error, variant = "h3" }) => (
  <>
    {error && (
      <Typography style={{ color: "red" }} variant={variant}>
        {error}
      </Typography>
    )}
  </>
);

ErrorText.propTypes = {
  error: PropTypes.string,
  variant: PropTypes.string,
};

export default ErrorText;
