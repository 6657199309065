import ExhibitionIcon from "../Components/icons/ExhibitionIcon";
import FestivalIcon from "../Components/icons/FestivalIcon";
import ExerciseIcon from "../Components/icons/ExerciseIcon";
import OtherIcon from "../Components/icons/OtherIcon";
import ConcertIcon from "../Components/icons/ConcertIcon";
import SportIcon from "../Components/icons/SportIcon";
import FolkfestivalIcon from "../Components/icons/FolkfestivalIcon";
import Children13Icon from "../Components/icons/Children13Icon";
import ChildrenIcon from "../Components/icons/ChildrenIcon";
import TeenagerIcon from "../Components/icons/TeenagerIcon";
import TeenagerAdultsIcon from "../Components/icons/TeenagerAdultsIcon";
import AdultsIcon from "../Components/icons/AdultsIcon";
import InternationalIcon from "../Components/icons/InternationalIcon";
import NationalIcon from "../Components/icons/NationalIcon";
import RecurringIcon from "../Components/icons/RecurringIcon";

export const eventTypeIcons = {
  exhibition: ExhibitionIcon,
  festival: FestivalIcon,
  "sport/exercise": ExerciseIcon,
  other: OtherIcon,
  concert: ConcertIcon,
  sport: SportIcon,
  // "awards": ,
  folkfestival: FolkfestivalIcon,
};

export const ageGroupIcons = {
  children13: Children13Icon,
  children: ChildrenIcon,
  teenager: TeenagerIcon,
  "teenager/adults": TeenagerAdultsIcon,
  adults: AdultsIcon,
};

export const othersIcons = {
  international: InternationalIcon,
  nationality: NationalIcon,
  recurring: RecurringIcon,
};
