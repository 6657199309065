const createImage = (url, crossOrigin = "anonymous") =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));
    crossOrigin && image.setAttribute("crossOrigin", crossOrigin);
    image.src = url;
  });

export default createImage;
