/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

const SportIcon = ({ width = 20, height = 19, fill = "#203934", className = "" }) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 20 19" className={className}>
    <path
      d="M19.9984 4.69973C19.9765 4.11973 19.7299 3.58313 19.3041 3.18871L17.9477 1.93234C16.6024 0.686211 14.8516 0 13.0179 0C12.5132 0 12.0891 0.394258 12.0522 0.897657L12.0318 1.17672C11.9597 2.16246 11.1291 2.93461 10.1407 2.93461C8.99536 2.93461 8.03286 2.03988 7.94934 0.897657C7.91258 0.394297 7.48844 0 6.98281 0C5.14973 0 3.39938 0.68586 2.05426 1.93117L0.696287 3.1884C0.27031 3.58285 0.0235915 4.11957 0.00159933 4.69973C-0.0203929 5.27985 0.185037 5.83375 0.580037 6.25926L1.54652 7.30047C1.94183 7.72629 2.48117 7.97231 3.06184 7.99325C3.37473 8.00434 3.68668 7.94965 3.96316 7.83461V17.643C3.96316 18.0248 4.26883 18.3376 4.65059 18.3376H15.3511C15.7328 18.3376 16.0369 18.0248 16.0369 17.643V7.83446C16.3134 7.94922 16.6251 8.00379 16.9379 7.9927C17.5188 7.97184 18.0578 7.72575 18.4531 7.29965L19.4195 6.25891C19.8143 5.83344 20.0204 5.27973 19.9984 4.69973ZM4.17914 5.93102L3.67629 6.40004C3.66582 6.40977 3.65469 6.4184 3.64379 6.4275L1.58754 4.25196C1.60289 4.23508 1.61863 4.2186 1.63555 4.20293L2.26156 3.62336L4.33059 5.81246C4.33305 5.81504 4.33512 5.81789 4.3375 5.82051C4.2807 5.84934 4.22727 5.88618 4.17914 5.93102ZM7.78805 16.9551H6.86641V2.18504C7.06074 2.62485 7.34352 3.02461 7.7036 3.35934C7.73125 3.38504 7.75973 3.40953 7.78805 3.43422V16.9551ZM10.4609 16.9552H9.53922V4.26563C9.73649 4.2991 9.93747 4.31711 10.1408 4.31711C10.2487 4.31711 10.3554 4.31157 10.4609 4.30117V16.9552ZM13.1797 16.9552H12.2581V3.53473C12.6587 3.19445 12.9782 2.76016 13.1797 2.26488V16.9552ZM16.3479 6.4193C16.3401 6.41254 16.3319 6.40645 16.3243 6.39938L15.8227 5.93121C15.7711 5.88301 15.7133 5.84399 15.6517 5.81426C15.6522 5.81371 15.6527 5.81305 15.6532 5.81246L17.73 3.61512L18.3647 4.20301C18.3786 4.2159 18.3914 4.22973 18.4043 4.24348L16.3479 6.4193Z"
      fill={fill}
    />
  </svg>
);

SportIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default SportIcon;
