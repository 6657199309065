import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { dateFilterDateFormat } from "../constants";
import { formatDate } from "../helpers";
import FilterButton from "./FilterButton";
import DateRangePicker from "./DateRangePicker";
import Menu from "@material-ui/core/Menu";
import ListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    outline: "none",
    "& strong": {
      fontSize: "1rem",
    },
    "& .MuiPickersBasePicker-pickerView": {
      minHeight: 315,
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: theme.spacing(4),
  },
  button: {
    borderRadius: 50,
  },
}));

const DateFilterMenu = ({ buttonText = "", activeFields = {}, onSave }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { startDate, endDate } = activeFields;

  const handleOpen = event => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <FilterButton
        active={String(Boolean(startDate || endDate))}
        variant="contained"
        aria-controls="dates-filter-menu"
        aria-haspopup="true"
        onClick={handleOpen}
      >
        {buttonText}
        {(startDate || endDate) && (
          <>
            {" "}
            • {formatDate(new Date(startDate), dateFilterDateFormat)} -{" "}
            {formatDate(new Date(endDate), dateFilterDateFormat)}
          </>
        )}
      </FilterButton>
      <Menu
        variant="selectedMenu"
        id="dates-filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ListItem className={classes.container}>
          <DateRangePicker value={[new Date(startDate), new Date(endDate)]} onSave={onSave} />
        </ListItem>
      </Menu>
    </div>
  );
};

DateFilterMenu.propTypes = {
  buttonText: PropTypes.string.isRequired,
  activeFields: PropTypes.shape({
    startDate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.instanceOf(Date)]),
    endDate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.instanceOf(Date)]),
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default DateFilterMenu;
