import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import {
  ageGroupIcons,
  ageGroupMap,
  audienceMap,
  eventCategoryMap,
  eventPropTypes,
  eventSingleDateFormat,
  eventTypeMap,
  googleMapsApiKey,
  locationsMap,
  participantsMap,
  priceMap,
  strategicScoresMap,
  tagsMap,
} from "../constants";
import { formatDate, getDateWithAltDates } from "../helpers";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import defaultImage from "../assets/default-image.png";
import IconWrapper from "./IconWrapper";
import AudienceIcon from "./icons/AudienceIcon";
import StarIcon from "./icons/StarIcon";
import SpeakerIcon from "./icons/SpeakerIcon";

const useStyles = makeStyles(theme => ({
  article: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(12.625),
    },
  },
  section: {
    display: "flex",
    flexWrap: "wrap",
    boxShadow: "0 0 6px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },
  },
  sectionInfo: {
    "& > div": {
      flexBasis: "100%",
      padding: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        flexBasis: "50%",
        padding: theme.spacing(4),
      },
    },
    "& > div + div": {
      [theme.breakpoints.up("sm")]: {
        borderLeft: "1px solid #e5e5e5",
      },
    },
  },
  img: {
    width: "100%",
    height: "auto",
    display: "block",
    flexShrink: "0",
    objectFit: "cover",
    [theme.breakpoints.up("sm")]: {
      width: 260,
    },
    [theme.breakpoints.up("md")]: {
      width: 360,
    },
  },
  descrBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    padding: theme.spacing(3),
  },
  link: {
    wordBreak: "break-all",
  },
}));

const SingleEvent = ({ event = {} }) => {
  const {
    name = "",
    startDate: startDateDefault,
    endDate: endDateDefault,
    alternativeStartDates = [],
    alternativeEndDates = [],
    type,
    category,
    link = "",
    imageData = {},
    description = "",
    score,
    price,
    ageGroup = "",
    audience = "",
    participants = "",
    primaryFocus = "",
    international,
    recurring,
    location,
    indoors,
    outdoor,
    subsidiary,
    moves,
    troubles,
    excitement,
    strategicScores = [],
    tags = [],
  } = event;
  const classes = useStyles();
  const theme = useTheme();
  const overMd = useMediaQuery(theme => theme.breakpoints.up("md"));
  const { t } = useTranslation();
  const AgeGroupIcon = ageGroupIcons[ageGroup];
  const [startDate, endDate] = getDateWithAltDates(
    startDateDefault,
    endDateDefault,
    alternativeStartDates,
    alternativeEndDates,
  );

  return (
    <article className={classes.article}>
      <Box className={classes.section} mb={4}>
        <img className={classes.img} src={imageData.url || defaultImage} alt="Event" width="360" height="360" />
        <div className={classes.descrBox}>
          <Grid container direction="column" justify="space-between" spacing={1}>
            <Grid item>
              <Box mb={1}>
                <Box color="text.primary">
                  <Typography variant="h6" component="h2">
                    {name}
                  </Typography>
                </Box>
              </Box>
              {startDate && endDate && (
                <Box mb={1}>
                  <Typography variant="body1" component="h3">
                    {formatDate(new Date(startDate), eventSingleDateFormat)} -{" "}
                    {formatDate(new Date(endDate), eventSingleDateFormat)}
                  </Typography>
                </Box>
              )}
              <Box mt={3}>
                <Grid container justify="space-between" wrap="wrap" spacing={1}>
                  <Grid item xs={12} sm={5} md={3} lg={3}>
                    <Typography variant="body1" component="h5">
                      <strong>{t("Type")}</strong>
                    </Typography>
                    <Typography variant="body1" component="p">
                      {t(eventTypeMap[type]) || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <Typography variant="body1" component="h5">
                      <strong>{t("Category")}</strong>
                    </Typography>
                    <Typography variant="body1" component="p">
                      {t(eventCategoryMap[category]) || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={5} md={2} lg={3}>
                    <Typography variant="body1" component="h5">
                      <strong>{t("Recurring")}</strong>
                    </Typography>
                    <Typography variant="body1" component="p">
                      {t(recurring ? "Yes" : "No")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7} md={4} lg={3}>
                    <Typography variant="body1" component="h5">
                      <strong>
                        {t("National")}/{t("International")}
                      </strong>
                    </Typography>
                    <Typography variant="body1" component="p">
                      {t(international ? "International" : "National")}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {description && (
                <Box mt={overMd ? 5 : 4}>
                  <Typography variant="body1" component="h5">
                    <strong>{t("Description")}</strong>
                  </Typography>
                  <Typography variant="body1" color="inherit">
                    {description}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          {link && (
            <Grid item>
              <Typography variant="body1" component="h5">
                <strong>{t("Link")}</strong>
              </Typography>
              <Link className={classes.link} href={link} variant="body1">
                {link}
              </Link>
            </Grid>
          )}
        </div>
      </Box>

      <Box className={clsx(classes.section, classes.sectionInfo)} mb={overMd ? 7.5 : 6}>
        <div>
          <Box mb={overMd ? 6 : 4}>
            <Box color="text.primary">
              <Typography variant="subtitle1" component="h5" paragraph>
                <strong>{t("People Attending")}</strong>
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <Box display="flex">
                  <Box mr={1}>
                    <IconWrapper>
                      <SpeakerIcon fill={theme.palette.secondary.main} />
                    </IconWrapper>
                  </Box>
                  <div>
                    <Typography variant="body1" component="p" gutterBottom>
                      <strong>{t("Participants")}</strong>
                    </Typography>
                    <Typography variant="body1" component="p">
                      {t(participantsMap[participants]) || t("Not available")}
                    </Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <Box display="flex">
                  <Box mr={1}>
                    <IconWrapper>
                      <AudienceIcon fill={theme.palette.secondary.main} />
                    </IconWrapper>
                  </Box>
                  <div>
                    <Typography variant="body1" component="p" gutterBottom>
                      <strong>{t("Audience")}</strong>
                    </Typography>
                    <Typography variant="body1" component="p">
                      {t(audienceMap[audience]) || t("Not available")}
                    </Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <Box display="flex">
                  <Box mr={1}>
                    <IconWrapper>{AgeGroupIcon && <AgeGroupIcon fill={theme.palette.secondary.main} />}</IconWrapper>
                  </Box>
                  <div>
                    <Typography variant="body1" component="p" gutterBottom>
                      <strong>{t("Target Group")}</strong>
                    </Typography>
                    <Typography variant="body1" component="p">
                      {t(ageGroupMap[ageGroup])}
                    </Typography>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Box color="text.primary">
              <Typography variant="subtitle1" component="h5" paragraph>
                <strong>
                  {t("Venue")} & {t("Event")}
                </strong>
              </Typography>
            </Box>

            <Grid container spacing={2}>
              {(indoors || outdoor) && (
                <Grid item xs={12} sm={12} md={4}>
                  <div>
                    <Typography variant="body1" component="p" gutterBottom>
                      <strong>
                        {t("Indoors")} / {t("Outdoors")}
                      </strong>
                    </Typography>
                    <Typography variant="body1" component="p">
                      {indoors && t("Indoors")}
                      {indoors && outdoor && ", "}
                      {outdoor && t("Outdoors")}
                    </Typography>
                  </div>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={4}>
                <div>
                  <Typography variant="body1" component="p" gutterBottom>
                    <strong>{t("Price Range")}</strong>
                  </Typography>
                  <Typography variant="body1" component="p">
                    {t(priceMap[price]) || t("Not available")}
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <div>
                  <Typography variant="body1" component="p" gutterBottom>
                    <strong>{t("Primært BtB / BtC")}</strong>
                  </Typography>
                  <Typography variant="body1" component="p">
                    {t(primaryFocus || "btc")}
                  </Typography>
                </div>
              </Grid>

              {tags.length > 0 && (
                <Grid item xs={12}>
                  <div>
                    <Typography variant="body1" component="p" gutterBottom>
                      <strong>{t("Brand Theme")}</strong>
                    </Typography>
                    {tags.map(tag => (
                      <Typography key={tag} variant="body1" component="p" gutterBottom>
                        {t(tagsMap[tag])}
                      </Typography>
                    ))}
                  </div>
                </Grid>
              )}
            </Grid>
          </Box>
        </div>

        <div>
          <Box mb={3} color="text.primary">
            <Typography variant="subtitle1" component="h5">
              <strong>{t("Strategic Info")}</strong>
            </Typography>
          </Box>

          <Box mb={overMd ? 4 : 2}>
            <Typography variant="body1" component="h5">
              <strong>{t("Subsidiary from the City")}</strong>
            </Typography>
            <Typography variant="body1" component="p">
              {t(subsidiary ? "Yes" : "No")}
            </Typography>
          </Box>

          <Box mb={overMd ? 4 : 3}>
            <Typography variant="body1" component="p">
              <strong>
                {t("Strategic Score")}
                <br />
                {(strategicScores.length === score ? score : strategicScores.length) || 0}
              </strong>
            </Typography>
            {strategicScores.map(score => (
              <Box key={score} display="flex">
                <Box mr={1}>
                  <StarIcon fill={theme.palette.secondary.dark} width={10} height={10} />
                </Box>
                <Typography key={score} variant="body1" component="p" gutterBottom>
                  {t(strategicScoresMap[score])}
                </Typography>
              </Box>
            ))}
          </Box>

          {(moves || troubles || excitement) && (
            <Box>
              <Typography variant="body1" component="h5">
                <strong>{t("Emotions")}</strong>
              </Typography>
              {moves && (
                <Typography variant="body1" component="p">
                  {t("Moves through the city")}
                </Typography>
              )}
              {troubles && (
                <Typography variant="body1" component="p">
                  {t("Trouble for city")}
                </Typography>
              )}
              {excitement && (
                <Typography variant="body1" component="p">
                  {t("Excitement for city")}
                </Typography>
              )}
            </Box>
          )}
        </div>
      </Box>

      <Box>
        <Typography variant="body1" component="h5">
          <strong>{t("Location")}</strong>
        </Typography>
        <Typography variant="body1" component="h5" paragraph>
          {locationsMap[location]}
        </Typography>
        <iframe
          title={`Map: ${locationsMap[location]}`}
          width="100%"
          height={overMd ? 487 : 340}
          src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsApiKey}&q=${location}`}
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          allowFullScreen
        />
      </Box>
    </article>
  );
};

SingleEvent.propTypes = {
  event: eventPropTypes,
};

export default SingleEvent;
