import React from "react";
import { Redirect } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { pathRoutes } from "../constants";
import { useAuth } from "../Provider/AuthProvider";
import SignInForm from "../Components/SignInForm";
import Box from "@material-ui/core/Box";

const SignInPage = () => {
  const { user } = useAuth();

  return (
    <>
      {user && <Redirect exact from={pathRoutes.signIn} to="/" />}
      <Box mt={8} mb={8}>
        <Container maxWidth="md">
          <SignInForm />
        </Container>
      </Box>
    </>
  );
};

export default SignInPage;
