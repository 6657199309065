import { useState } from "react";

const usePagination = (data, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  const getCurrentData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  };

  const nextPage = () => setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));

  const prevPage = () => setCurrentPage(currentPage => Math.max(currentPage - 1, 1));

  const jumpToPage = page => setCurrentPage(() => Math.min(Math.max(1, page), maxPage));

  return { nextPage, prevPage, jumpToPage, getCurrentData, currentPage, maxPage };
};

export default usePagination;
