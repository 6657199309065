/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

const Children13Icon = ({ width = 20, height = 19, fill = "#203934", className = "" }) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 20 19" className={className}>
    <path
      d="M14.5912 4.60186C15.7365 4.60186 16.6649 3.67347 16.6649 2.52823C16.6649 1.38299 15.7365 0.45459 14.5912 0.45459C13.446 0.45459 12.5176 1.38299 12.5176 2.52823C12.5176 3.67347 13.446 4.60186 14.5912 4.60186Z"
      fill={fill}
    />
    <path
      d="M19.8757 10.8855L16.6844 5.40774C16.5315 5.14535 16.2211 4.95989 15.9872 4.95989C15.9311 4.95989 13.3268 4.95989 13.2707 4.95989C13.0368 4.95989 12.7264 5.14535 12.5735 5.40774L10.169 9.5349L7.49983 4.95338C7.32994 4.66172 7.0232 4.50006 6.70823 4.50063C6.70246 4.50028 6.69673 4.49976 6.69087 4.49976H4.12464C4.11878 4.49976 4.11305 4.50028 4.10727 4.50063C3.79231 4.50002 3.48561 4.66168 3.31568 4.95338L0.124284 10.4311C-0.129579 10.8668 0.017866 11.4258 0.453553 11.6797C0.598156 11.7639 0.756273 11.8039 0.912335 11.8039C1.2266 11.8039 1.53247 11.6415 1.70209 11.3504L3.10971 8.93427V9.9687L2.47973 14.5082C2.42668 14.8906 2.72371 15.2317 3.10976 15.2317V18.0103C3.10976 18.5327 3.51601 18.9777 4.03808 18.9963C4.58622 19.0158 5.03678 18.5772 5.03678 18.0334V15.2316H5.79802V18.0103C5.79802 18.5327 6.20427 18.9777 6.72643 18.9962C7.27457 19.0157 7.725 18.5772 7.725 18.0334V15.2316C8.11104 15.2316 8.40807 14.8905 8.35502 14.5081L7.725 9.96853V8.96733L9.11328 11.3503C9.16052 11.4313 9.4883 12.004 9.71133 12.134C9.85593 12.2182 10.0141 12.2582 10.1701 12.2582C10.4844 12.2582 10.7902 12.0958 10.9599 11.8047L12.3278 9.45665L12.3147 17.5383C12.3147 18.0704 12.746 18.5018 13.2781 18.5018C13.2803 18.5018 13.2825 18.5015 13.2847 18.5015C13.2869 18.5015 13.2891 18.5018 13.2914 18.5018C13.8235 18.5018 14.2549 18.0704 14.2549 17.5383L14.2417 12.662C14.2417 12.4592 14.3946 12.2822 14.597 12.2691C14.8186 12.2548 15.0029 12.4303 15.0029 12.6489V17.5152C15.0029 18.0376 15.4092 18.4826 15.9312 18.5012C16.4794 18.5207 16.9299 18.0821 16.9299 17.5383V9.45669L18.2979 11.8047C18.4675 12.0959 18.7734 12.2583 19.0876 12.2583C19.2437 12.2583 19.4019 12.2182 19.5464 12.134C19.9822 11.8802 20.1296 11.3212 19.8757 10.8855Z"
      fill={fill}
    />
    <path
      d="M2.16724 4.10616L2.68052 4.06041C2.97568 4.03412 3.1837 3.75821 3.12592 3.46752C3.03783 3.0233 3.00739 2.4068 3.3762 2.02557C3.3758 2.04162 3.37497 2.05754 3.37497 2.07364C3.37497 3.21882 4.30338 4.14723 5.44857 4.14723C6.59371 4.14723 7.52212 3.21882 7.52212 2.07364C7.52212 2.05754 7.52129 2.04162 7.52085 2.02557C7.8897 2.40684 7.85922 3.0233 7.77108 3.46752C7.71343 3.75821 7.92137 4.03412 8.21652 4.06041L8.72976 4.10616C9.00107 4.13035 9.24155 3.93291 9.2706 3.66207C9.34123 3.00445 9.32894 2.01783 8.95703 1.62605C8.40806 1.04778 7.72909 1.04191 7.29047 1.12165C6.94555 0.455545 6.25048 0 5.44861 0C4.64674 0 3.95163 0.455501 3.60666 1.12165C3.16809 1.04196 2.48899 1.04778 1.94011 1.62605C1.56828 2.01783 1.55599 3.0045 1.62645 3.66207C1.65554 3.93291 1.89597 4.13035 2.16724 4.10616Z"
      fill={fill}
    />
  </svg>
);

Children13Icon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default Children13Icon;
