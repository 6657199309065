import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withTranslation } from "react-i18next";

const AlertDialog = ({
  isOpen = false,
  title = "",
  descr = "",
  confirmButtonText = "Ok",
  maxWidth = "sm",
  onConfirm,
  onCancel,
  onClose = () => {},
  t,
}) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    fullWidth
    maxWidth={maxWidth}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{descr}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        color="primary"
        onClick={event => {
          if (onCancel) onCancel(event);
          onClose(event);
        }}
      >
        {t("Close")}
      </Button>
      <Button
        color="secondary"
        onClick={event => {
          if (onConfirm) onConfirm(event);
          onClose(event);
        }}
      >
        {confirmButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);

AlertDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  descr: PropTypes.string,
  confirmButtonText: PropTypes.string,
  maxWidth: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  // From HOC
  t: PropTypes.func,
};

export default withTranslation()(AlertDialog);
