import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const transformToSelectOptions = (fields = [], empty = false) => [
  ...(empty ? [<MenuItem key="empty"> </MenuItem>] : []),
  ...fields.map(({ value, label, Icon }) => (
    <MenuItem key={value} value={value}>
      {Icon ? (
        <ListItemIcon style={{ minWidth: 22, marginRight: 6 }}>
          <Icon />
        </ListItemIcon>
      ) : (
        ""
      )}{" "}
      {"   "} {label}
    </MenuItem>
  )),
];

export default transformToSelectOptions;
