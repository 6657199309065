import { firestore, storage } from "../firebase";

const collectionKey = "events";

const transformCollectionListCb = querySnapshot => querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

const transformCollectionItemCb = doc => doc.data();

export const apiGetEvents = () =>
  firestore
    .collection(collectionKey)
    // .where("author", "==", user?.uid ?? "")
    .get()
    .then(transformCollectionListCb);

export const apiGetEvent = id =>
  firestore
    .collection(collectionKey)
    .doc(id)
    .get()
    .then(transformCollectionItemCb);

export const apiPostEvent = event => firestore.collection(collectionKey).add(event);

export const apiPutEvent = (id, event) =>
  firestore
    .collection(collectionKey)
    .doc(id)
    .set(event, { merge: true });

export const apiDeleteEvent = id =>
  firestore
    .collection(collectionKey)
    .doc(id)
    .delete();

export const uploadEventImage = (name, imageFile) => storage.ref(`/${collectionKey}/${name}`).put(imageFile);

export const deleteEventImage = name => storage.ref(`/${collectionKey}/${name}`).delete();
