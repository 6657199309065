/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

const OtherIcon = ({ width = 24, height = 18, fill = "#203934", className = "" }) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 24 18" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.89754 0H21.8975C22.9975 0 23.8975 0.9 23.8975 2V16C23.8975 17.1 22.9975 18 21.8975 18H6.96754C6.27754 18 5.66754 17.64 5.30754 17.12L0.267539 9.56C0.0475391 9.22 0.0475391 8.79 0.267539 8.45L5.30754 0.88C5.66754 0.35 6.20754 0 6.89754 0ZM7.39754 9C7.39754 9.83 8.06754 10.5 8.89754 10.5C9.72754 10.5 10.3975 9.83 10.3975 9C10.3975 8.17 9.72754 7.5 8.89754 7.5C8.06754 7.5 7.39754 8.17 7.39754 9ZM13.8975 10.5C13.0675 10.5 12.3975 9.83 12.3975 9C12.3975 8.17 13.0675 7.5 13.8975 7.5C14.7275 7.5 15.3975 8.17 15.3975 9C15.3975 9.83 14.7275 10.5 13.8975 10.5ZM17.3975 9C17.3975 9.83 18.0675 10.5 18.8975 10.5C19.7275 10.5 20.3975 9.83 20.3975 9C20.3975 8.17 19.7275 7.5 18.8975 7.5C18.0675 7.5 17.3975 8.17 17.3975 9Z"
      fill={fill}
    />
  </svg>
);

OtherIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default OtherIcon;
