import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { pathRoutes } from "../constants";
import { useAuth } from "../Provider/AuthProvider";
import PageLoader from "./PageLoader";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import SingOutIcon from "@material-ui/icons/ExitToApp";

const PrivateRoute = ({ component: Component, hiddenUserInfo, onlySuperAdmin, ...props }) => {
  const { user, signOut } = useAuth();
  const { t } = useTranslation();

  if (user == null) return <PageLoader />;

  return (
    <Route
      {...props}
      render={props =>
        typeof user === "object" && (!onlySuperAdmin || user.superAdmin) ? (
          <>
            {!hiddenUserInfo && user && (
              <>
                <Box textAlign="right" m={1} mt={-4.5} fontWeight="500">
                  <Typography variant="subtitle1">
                    {t("Hello")}, {user?.displayName || user?.email}
                    <IconButton aria-label="SingOut" onClick={signOut}>
                      <SingOutIcon />
                    </IconButton>
                  </Typography>
                </Box>
              </>
            )}
            <Component {...props} />
          </>
        ) : (
          <Redirect to={{ pathname: pathRoutes.signIn, state: { referrer: window.location.pathname } }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  hiddenUserInfo: PropTypes.bool,
  onlySuperAdmin: PropTypes.bool,
};

export default PrivateRoute;
