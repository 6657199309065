import React from "react";
import isSameDay from "date-fns/isSameDay";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  ageGroupMap,
  audienceMap,
  dateFilterDateFormat,
  eventPropTypes,
  eventTypeMap,
  participantsMap,
  pathRoutes,
  priceMap,
} from "../constants";
import { formatDate, getDateWithAltDates } from "../helpers";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import StarIcon from "./icons/StarIcon";
import AudienceIcon from "./icons/AudienceIcon";
import SpeakerIcon from "./icons/SpeakerIcon";
import defaultImage from "../assets/default-image.png";
import isValidDate from "date-fns/isValid";

const useStyles = makeStyles(theme => ({
  article: {
    display: "flex",
    padding: "15px 15px 18px",
    boxShadow: "0 0 6px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      paddingBottom: theme.spacing(1),
    },
  },
  thumb: {
    width: 160,
    marginRight: "0.5rem",
  },
  img: {
    display: "block",
    marginBottom: 8,
    objectFit: "cover",
  },
  link: {
    display: "block",
    fontSize: 10,
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  articleBoxContent: {
    padding: "0 0.5rem",
  },
  articleBoxContentLeft: {
    flexGrow: "1",
    [theme.breakpoints.down("xs")]: {
      order: "4",
      marginTop: theme.spacing(1.8),
      padding: 0,
      flexBasis: "100%",
    },
  },
  articleBoxContentRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minWidth: "140px",
    maxWidth: "190px",
    flexShrink: "0",
    borderLeft: "1px solid #f2f2f2",
  },
  gutterBottom: {
    marginBottom: "0.5rem",
  },
  descr: {
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      height: 107,
    },
  },
  textWithIcon: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.4rem",
  },
  iconWrapper: {
    width: 25,
    height: 22,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 5,
  },
  icon: {
    marginRight: 4,
  },
}));

const EventsListItem = ({ event = {} }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    id,
    startDate: startDateDefault,
    endDate: endDateDefault,
    alternativeStartDates = [],
    alternativeEndDates = [],
    type = "",
    name = "",
    link = "",
    description = "",
    score = "-",
    price = "",
    ageGroup = "",
    participants = "",
    audience = "",
    imageData = {},
  } = event;
  const [startDate, endDate] = getDateWithAltDates(
    startDateDefault,
    endDateDefault,
    alternativeStartDates,
    alternativeEndDates,
  );

  return (
    <article className={classes.article}>
      <div className={classes.thumb}>
        <img className={classes.img} src={imageData.url || defaultImage} alt="Event" width="160" height="160" />
        <Link className={classes.link} href={link} variant="body2">
          {link}
        </Link>
      </div>
      <div className={`${classes.articleBoxContent} ${classes.articleBoxContentLeft}`}>
        <Typography className={classes.gutterBottom} variant="body1" component="h3">
          {t(eventTypeMap[type])}
        </Typography>
        <Typography className={classes.gutterBottom} variant="body1" component="h2">
          <strong>
            <Link href={`${pathRoutes.event}/${id}`}>{name}</Link>
          </strong>
        </Typography>
        <Typography className={`${classes.gutterBottom} ${classes.descr}`} variant="body2">
          {description.slice(0, 290)}
          {description.length > 290 ? "..." : ""}
        </Typography>
      </div>
      <div className={`${classes.articleBoxContent} ${classes.articleBoxContentRight}`}>
        <div>
          <div className={classes.textWithIcon}>
            <div className={classes.iconWrapper}>
              <StarIcon width="18" height="18" className={classes.icon} fill={theme.palette?.secondary?.main} />
            </div>
            <Typography variant="body1">{score || 0}</Typography>
          </div>
          <Typography variant="body1">
            <strong>{t(priceMap[price])}</strong>
          </Typography>
        </div>
        <div>
          <div className={classes.textWithIcon}>
            <div className={classes.iconWrapper}>
              <SpeakerIcon className={classes.icon} fill={theme.palette?.secondary?.main} />
            </div>
            <Typography variant="body1">
              <strong>{t(participantsMap[participants]) || "-"}</strong>
            </Typography>
          </div>
          <div className={classes.textWithIcon}>
            <div className={classes.iconWrapper}>
              <AudienceIcon className={classes.icon} fill={theme.palette?.secondary?.main} />
            </div>
            <Typography variant="body1">
              <strong>{t(audienceMap[audience]) || "-"}</strong>
            </Typography>
          </div>
          <Typography variant="body1">{ageGroupMap[ageGroup]}</Typography>
          <Typography variant="body1">
            <strong>
              {isValidDate(new Date(startDate)) && formatDate(startDate, dateFilterDateFormat)}
              {isValidDate(new Date(startDate)) &&
                isValidDate(new Date(endDate)) &&
                !isSameDay(startDate, endDate) &&
                ` - ${formatDate(endDate, dateFilterDateFormat)}`}
            </strong>
          </Typography>
        </div>
      </div>
    </article>
  );
};

EventsListItem.propTypes = {
  event: eventPropTypes,
};

export default EventsListItem;
