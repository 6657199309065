import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { participantsFields, priceFields } from "../constants";
import FilterButton from "./FilterButton";
import FiltersMenuButtons from "./FiltersMenuButtons";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "inherit",
    width: 400,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    outline: "none",
    "& strong": {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      width: 660,
    },
  },
  title: {
    marginBottom: theme.spacing(3.3),
  },
  block: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: theme.spacing(3.4),
  },
}));

const MoreFilterMenu = ({ buttonText = "", activeFields = {}, checkboxColor = "secondary", onSave }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState(activeFields);
  const stateLength = Object.values(state).filter(Boolean).length;

  const handleOpen = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleChange = (name, checked) => setState(state => ({ ...state, [name]: checked }));
  const onChange = event => handleChange(event.target.name, event.target.checked);

  return (
    <div>
      <FilterButton
        active={String(Boolean(stateLength))}
        variant="contained"
        aria-controls="more-filter-menu"
        aria-haspopup="true"
        onClick={handleOpen}
      >
        {buttonText}
        {stateLength ? ` • ${stateLength}` : ""}
      </FilterButton>
      <Menu
        variant="selectedMenu"
        id="more-filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        component="div"
      >
        <ListItem className={classes.container} component="div">
          <Typography align="center" className={classes.title}>
            {t("More Filters")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <div>
                <div className={classes.block}>
                  <Typography gutterBottom>
                    {t("Indoor")}/{t("Outdoor")}
                  </Typography>
                  <FormControlLabel
                    label={t("Indoor")}
                    control={
                      <Checkbox
                        checked={state.indoors || false}
                        onChange={onChange}
                        name="indoors"
                        color={checkboxColor}
                      />
                    }
                  />
                  <FormControlLabel
                    label={t("Outdoor")}
                    control={
                      <Checkbox
                        checked={state.outdoor || false}
                        onChange={onChange}
                        name="outdoor"
                        color={checkboxColor}
                      />
                    }
                  />
                </div>

                <div className={classes.block}>
                  <Typography gutterBottom>{t("Nationality")}</Typography>
                  <FormControlLabel
                    label={t("Nationality")}
                    control={
                      <Checkbox
                        checked={state.nationality || false}
                        onChange={onChange}
                        name="nationality"
                        color={checkboxColor}
                      />
                    }
                  />
                  <FormControlLabel
                    label={t("International")}
                    control={
                      <Checkbox
                        checked={state.international || false}
                        onChange={onChange}
                        name="international"
                        color={checkboxColor}
                      />
                    }
                  />
                </div>

                <div className={classes.block}>
                  <Typography gutterBottom>{t("Recurring")}</Typography>
                  <FormControlLabel
                    label={t("Yes")}
                    control={
                      <Checkbox
                        checked={state["recurring:yes"] || false}
                        onChange={onChange}
                        name="recurring:yes"
                        color={checkboxColor}
                      />
                    }
                  />
                  <FormControlLabel
                    label={t("No")}
                    control={
                      <Checkbox
                        checked={state["recurring:no"] || false}
                        onChange={onChange}
                        name="recurring:no"
                        color={checkboxColor}
                      />
                    }
                  />
                </div>

                <div className={classes.block}>
                  <Typography gutterBottom>{t("Subsidiary from the City")}</Typography>
                  <FormControlLabel
                    label={t("Yes")}
                    control={
                      <Checkbox
                        checked={state["subsidiary:yes"] || false}
                        onChange={onChange}
                        name="subsidiary:yes"
                        color={checkboxColor}
                      />
                    }
                  />
                  <FormControlLabel
                    label={t("No")}
                    control={
                      <Checkbox
                        checked={state["subsidiary:no"] || false}
                        onChange={onChange}
                        name="subsidiary:no"
                        color={checkboxColor}
                      />
                    }
                  />
                </div>
              </div>

              <div className={classes.block}>
                <Typography gutterBottom>{t("Primary Focus")}</Typography>
                <FormControlLabel
                  label={t("btc")}
                  control={
                    <Checkbox
                      checked={state["primaryFocus:btc"] || false}
                      onChange={onChange}
                      name="primaryFocus:btc"
                      color={checkboxColor}
                    />
                  }
                />
                <FormControlLabel
                  label={t("btb")}
                  control={
                    <Checkbox
                      checked={state["primaryFocus:btb"] || false}
                      onChange={onChange}
                      name="primaryFocus:btb"
                      color={checkboxColor}
                    />
                  }
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Typography gutterBottom>{t("Participants")}</Typography>
              {participantsFields.map(({ value, label }) => (
                <div key={value}>
                  <FormControlLabel
                    label={t(label)}
                    control={
                      <Checkbox
                        checked={state[`participants:${value}`] || false}
                        onChange={onChange}
                        name={`participants:${value}`}
                        color={checkboxColor}
                      />
                    }
                  />
                </div>
              ))}
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Typography gutterBottom>{t("Price Range")}</Typography>
              {priceFields.map(({ value, label }) => (
                <div key={value}>
                  <FormControlLabel
                    label={t(label)}
                    control={
                      <Checkbox
                        checked={state[`price:${value}`] || false}
                        onChange={onChange}
                        name={`price:${value}`}
                        color={checkboxColor}
                      />
                    }
                  />
                </div>
              ))}
            </Grid>

            <FiltersMenuButtons
              topSpacing={2}
              onClear={() => setState({})}
              onSave={() =>
                onSave(
                  Object.entries(state)
                    .filter(([, isTrue]) => isTrue)
                    .reduce((acc, [key, value]) => {
                      const [arrayKey, subKey] = key.split(":");
                      if (subKey) return { ...acc, [arrayKey]: { ...(acc[arrayKey] || {}), [subKey]: value } };
                      return { ...acc, [key]: value };
                    }, {}),
                )
              }
            />
          </Grid>
        </ListItem>
      </Menu>
    </div>
  );
};

MoreFilterMenu.propTypes = {
  buttonText: PropTypes.string.isRequired,
  activeFields: PropTypes.object.isRequired,
  checkboxColor: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

export default MoreFilterMenu;
