export const pathRoutes = {
  root: "/events/list",
  signIn: "/sign-in",
  events: "/events",
  event: "/event",
  admin: "/admin",
  adminEvents: "/admin/events",
  createEvent: "/admin/create-event",
  eventId: "eventId",
  gantt: "gantt",
  list: "list",
  map: "map",
  eventsType: "eventsType",
};
