import React, { useEffect } from "react";
import PropTypes from "prop-types";
import isValidDate from "date-fns/isValid";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { locationsMap, pathRoutes } from "../constants";
import { isEmptyObject } from "../helpers";
import { apiGetEvents } from "../services";
import useLoadData from "../hooks/useLoadData";
import useEventsFilters from "../hooks/useEventsFilters";
import usePagination from "../hooks/usePagination";
import useEventsSort from "../hooks/useEventsSort";
import EventsMap from "../Components/EventsMap";
import Gantt from "../Components/Gantt";
import EventsList from "../Components/EventsList";
import PageLoader from "../Components/PageLoader";
import PageErrorMessage from "../Components/PageErrorMessage";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
// import { apiPostEvent } from "../services";
// import eventsMoc from "../eventsMoc";

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(4.25),
    marginBottom: theme.spacing(2),
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3.4),
  },
}));

const EventsPage = ({ match = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const type = match.params?.[pathRoutes.eventsType];
  const isMap = type === pathRoutes.map;
  const isGantt = type === pathRoutes.gantt;
  const isList = type === pathRoutes.list;
  const [{ data, isLoading, error }] = useLoadData([], apiGetEvents, false);
  const { events: filteredEvents, filters = {}, Component: EventsFilters, DateFilterMenuComponent } = useEventsFilters(
    data,
  );
  const { events: sortedEvents, Component: EventsSort } = useEventsSort(filteredEvents, "startDate");
  const events = isGantt
    ? sortedEvents.filter(
        ({ startDate, endDate }) => isValidDate(new Date(startDate)) && isValidDate(new Date(endDate)),
      )
    : sortedEvents;
  const { currentPage, maxPage, jumpToPage, getCurrentData } = usePagination(events, 8);
  const pageEvents = getCurrentData();
  const eventsLength = events.length;

  // useEffect(() => {
  //   eventsMoc.forEach(item => apiPostEvent(item));
  // }, []);

  useEffect(() => {
    if (isList && maxPage > 0 && maxPage < currentPage) {
      jumpToPage(maxPage);
    }
  }, [isList, currentPage, maxPage, jumpToPage]);

  if (isLoading) return <PageLoader />;
  if (error) return <PageErrorMessage error={error} />;

  const title = `${eventsLength} ${eventsLength !== 1 ? t("Events") : t("Event")} ${
    !isEmptyObject(filters.location || {})
      ? `${t("in")} ${Object.keys(filters.location)
          .map(location => locationsMap[location])
          .join(", ")}`
      : ""
  }`;

  return (
    <Container maxWidth={false} style={isGantt ? { overflow: "hidden" } : {}}>
      <EventsFilters isShowDateFilter={!isGantt} />

      {isMap && <EventsMap events={events} title={title} />}

      {isGantt && (
        <Gantt
          events={events}
          title={title}
          filterDates={filters.dates}
          EventsSort={EventsSort}
          DateFilterMenu={DateFilterMenuComponent}
        />
      )}

      {isList && (
        <>
          <Box color="text.primary">
            <Typography className={classes.title} variant="h6">
              {title}
            </Typography>
          </Box>
          <EventsList events={pageEvents} />
          {events.length > 0 && (
            <Pagination
              className={classes.pagination}
              count={maxPage}
              page={currentPage}
              onChange={(event, page) => jumpToPage(page)}
              showFirstButton
              showLastButton
              color="secondary"
            />
          )}
        </>
      )}
    </Container>
  );
};

EventsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      [pathRoutes.eventsType]: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default EventsPage;
