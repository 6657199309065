/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

const TeenagerAdultsIcon = ({ width = 20, height = 20, fill = "#203934", className = "" }) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 20 20" className={className}>
    <path
      d="M9.99993 4.99985C11.3806 4.99985 12.4999 3.8806 12.4999 2.49993C12.4999 1.11925 11.3806 0 9.99993 0C8.61925 0 7.5 1.11925 7.5 2.49993C7.5 3.8806 8.61925 4.99985 9.99993 4.99985Z"
      fill={fill}
    />
    <path
      d="M13.7447 11.7948L13.1772 7.37497C13.1135 6.73374 12.5785 6.25 11.9348 6.25H11.2498C11.0835 6.25 10.9248 6.31625 10.8073 6.43249L9.99983 7.23997L9.19235 6.43249C9.07486 6.31625 8.91611 6.25 8.74987 6.25H8.06614C7.42116 6.25 6.88617 6.73374 6.82367 7.35872L6.25494 11.7961C6.23244 11.9736 6.28744 12.1536 6.40619 12.2873C6.52493 12.4223 6.69493 12.4998 6.87492 12.4998H7.5499L8.07239 18.8746C8.13489 19.5146 8.67112 19.9996 9.3161 19.9996H10.6848C11.3298 19.9996 11.8648 19.5146 11.9298 18.8634L12.451 12.4998H13.1247C13.3047 12.4998 13.4747 12.4223 13.5935 12.2873C13.7135 12.1536 13.7685 11.9736 13.7447 11.7948Z"
      fill={fill}
    />
    <path
      d="M16.2499 4.99985C17.6306 4.99985 18.7499 3.8806 18.7499 2.49993C18.7499 1.11925 17.6306 0 16.2499 0C14.8693 0 13.75 1.11925 13.75 2.49993C13.75 3.8806 14.8693 4.99985 16.2499 4.99985Z"
      fill={fill}
    />
    <path
      d="M3.74992 0C2.37122 0 1.25 1.12122 1.25 2.49993C1.25 3.87863 2.37122 4.99985 3.74992 4.99985C5.12738 4.99985 6.24985 3.87863 6.24985 2.49993C6.24985 1.12122 5.12738 0 3.74992 0Z"
      fill={fill}
    />
    <path
      d="M19.9943 11.7948L19.4268 7.37622C19.363 6.73374 18.8293 6.25 18.1843 6.25H17.4993C17.3331 6.25 17.1744 6.31625 17.0569 6.43249L16.2494 7.23997L15.4419 6.43249C15.3244 6.31625 15.1657 6.25 15.0007 6.25H14.3157C14.2382 6.25 14.1682 6.2775 14.0944 6.29125C14.2557 6.57624 14.3819 6.88498 14.4194 7.23122L14.9857 11.6361C15.0557 12.1723 14.8882 12.7123 14.5282 13.1185C14.3582 13.3135 14.1407 13.451 13.9082 13.5585L14.3232 17.6247C14.3869 18.2659 14.9219 18.7496 15.5669 18.7496H16.9356C17.5806 18.7496 18.1156 18.2646 18.1806 17.6134L18.7006 12.4998H19.3743C19.5543 12.4998 19.7243 12.4223 19.843 12.2873C19.963 12.1536 20.018 11.9736 19.9943 11.7948Z"
      fill={fill}
    />
    <path
      d="M5.46732 13.1123C5.11233 12.7111 4.94733 12.1723 5.01483 11.6386L5.58357 7.19997C5.61606 6.86748 5.73856 6.56749 5.89481 6.29C5.82481 6.2775 5.75856 6.25 5.68481 6.25H4.99983C4.83359 6.25 4.67484 6.31625 4.55735 6.43249L3.74987 7.23997L2.94239 6.43249C2.8249 6.31625 2.66615 6.25 2.50116 6.25H1.81614C1.17116 6.25 0.636173 6.73374 0.573675 7.35872L0.00494194 11.7961C-0.0175574 11.9736 0.037441 12.1536 0.156187 12.2873C0.274934 12.4223 0.446179 12.4998 0.624924 12.4998H1.2999L1.82239 17.6247C1.88489 18.2646 2.42112 18.7496 3.0661 18.7496H4.43481C5.07979 18.7496 5.61478 18.2646 5.67977 17.6134L6.09476 13.5573C5.86106 13.4485 5.64231 13.3098 5.46732 13.1123Z"
      fill={fill}
    />
  </svg>
);

TeenagerAdultsIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default TeenagerAdultsIcon;
