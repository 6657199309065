import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  // enUS
  nlNL,
} from "@material-ui/core/locale";
import { I18nextProvider } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import daLocale from "date-fns/locale/da";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import i18n from "./i18n";
import ProvideAuth from "./Provider/AuthProvider";
import Routers from "./routes/Routers";
import defaultTheme from "./defaultTheme";
require("typeface-montserrat");

const localeMap = {
  en: enLocale,
  da: daLocale,
};

const theme = createMuiTheme(defaultTheme, nlNL);
window.__localeId__ = "da";

const App = () => (
  <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <I18nextProvider i18n={i18n}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap.da}>
          <ProvideAuth>
            <Router>
              <Routers />
            </Router>
          </ProvideAuth>
        </MuiPickersUtilsProvider>
      </I18nextProvider>
    </ThemeProvider>
  </>
);

export default App;
