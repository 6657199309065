import React from "react";
import PropTypes from "prop-types";
import ErrorText from "./ErrorText";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

const PageErrorMessage = ({ error, maxWidth = "md", variant = "h3", textAlign = "center" }) => (
  <Container maxWidth={maxWidth}>
    <Box textAlign={textAlign}>
      <ErrorText error={error} variant={variant} />
    </Box>
  </Container>
);

PageErrorMessage.propTypes = {
  error: PropTypes.string,
  maxWidth: PropTypes.string,
  variant: PropTypes.string,
  textAlign: PropTypes.string,
};

export default PageErrorMessage;
