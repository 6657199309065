import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { debounce } from "../helpers";
import FilterButton from "./FilterButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";

const CheckboxesFilterMenu = ({
  ariaId = "",
  buttonText = "",
  fields = [],
  activeFields = {},
  multiple = false,
  checkboxColor = "secondary",
  onSave,
  children,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState(fields.reduce((acc, { value }) => ({ ...acc, [value]: activeFields[value] }), {}));
  const stateLength = Object.values(state).filter(Boolean).length;

  const handleOpen = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleChange = (name, checked) => setState(state => ({ ...(multiple ? state : {}), [name]: checked }));
  const handleSave = useCallback(
    debounce(state => onSave(Object.fromEntries(Object.entries(state).filter(([, isTrue]) => isTrue))), 300),
    [],
  );

  const getButtonText = () => {
    if (!multiple) {
      return fields.find(({ value }) => state[value])?.label || buttonText;
    } else if (stateLength) {
      return `${buttonText} ${stateLength ? ` • ${stateLength}` : ""}`;
    } else {
      return buttonText;
    }
  };

  useEffect(() => {
    handleSave(state);
  }, [state, handleSave]);

  return (
    <div>
      <FilterButton
        active={String(Boolean(stateLength))}
        variant="contained"
        aria-controls={`${ariaId}-filter-menu`}
        aria-haspopup="true"
        onClick={handleOpen}
      >
        {getButtonText()}
      </FilterButton>
      <Menu
        variant="selectedMenu"
        id={`${ariaId}-filter-menu`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {fields.map(({ value, label }) => (
          <MenuItem key={value} onClick={() => handleChange(value, !state[value])}>
            <ListItemIcon>
              <Checkbox checked={state[value] || false} name={value} color={checkboxColor} />
            </ListItemIcon>
            <ListItemText>{t(label)}</ListItemText>
          </MenuItem>
        ))}
        {children && <ListItem>{children}</ListItem>}
      </Menu>
    </div>
  );
};

CheckboxesFilterMenu.propTypes = {
  ariaId: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  activeFields: PropTypes.object.isRequired,
  multiple: PropTypes.bool,
  checkboxColor: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default CheckboxesFilterMenu;
