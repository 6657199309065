/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

const AdultsIcon = ({ width = 20, height = 20, fill = "#203934", className = "" }) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 20 20" className={className}>
    <path
      d="M4.60938 13.6719V7.89062H3.55469V6.32812H6.17188V13.6719H4.60938ZM8.76816 9.28238C8.48785 8.99185 8.28125 8.62259 8.28125 8.16498C8.28125 7.27615 8.8559 6.32629 10.4688 6.32629C12.0816 6.32629 12.6562 7.27615 12.6562 8.16498C12.6562 8.61786 12.4538 8.98438 12.1777 9.27353C13.0916 9.86404 13.5547 10.5492 13.5547 11.3121C13.5547 12.6122 12.4982 13.67 11.1996 13.67H9.73785C8.43933 13.67 7.38281 12.6122 7.38281 11.3121C7.38281 10.5615 7.84882 9.8793 8.76816 9.28238ZM11.9922 11.3121C11.9922 10.9912 11.2965 10.4985 10.5765 10.1851L10.4688 10.22L10.3734 10.1891C10.1849 10.2733 9.95041 10.3883 9.71939 10.5296C9.10828 10.903 8.94531 11.1971 8.94531 11.3121C8.94531 11.7506 9.30084 12.1075 9.73785 12.1075H11.1996C11.6367 12.1075 11.9922 11.7506 11.9922 11.3121ZM9.84421 8.13919C9.90768 8.25073 10.1831 8.43231 10.4678 8.5611C10.7565 8.43079 11.0298 8.25073 11.0933 8.13919C11.0895 8.03192 11.0611 8.01331 11.0359 7.99667C10.9975 7.97134 10.8429 7.88879 10.4688 7.88879C10.0946 7.88879 9.94003 7.97134 9.90158 7.99667C9.8764 8.01331 9.84802 8.03192 9.84421 8.13919ZM10 18.4375C5.3476 18.4375 1.5625 14.6524 1.5625 10C1.5625 5.3476 5.3476 1.5625 10 1.5625C13.2123 1.5625 16.0107 3.36716 17.4356 6.01562H19.1771C18.683 4.87823 17.9744 3.83224 17.0711 2.92892C15.1823 1.04019 12.6711 0 10 0C7.32895 0 4.81766 1.04019 2.92892 2.92892C1.04019 4.81766 0 7.32895 0 10C0 12.6711 1.04019 15.1823 2.92892 17.0711C4.81766 18.9598 7.32895 20 10 20C12.6711 20 15.1823 18.9598 17.0711 17.0711C17.8064 16.3359 18.4123 15.506 18.8791 14.6094H17.0636C15.5556 16.9127 12.9527 18.4375 10 18.4375ZM18.0469 9.53125V7.57812H16.4844V9.53125H14.5312V11.0938H16.4844V13.0469H18.0469V11.0938H20V9.53125H18.0469Z"
      fill={fill}
    />
  </svg>
);

AdultsIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default AdultsIcon;
