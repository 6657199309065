import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { imageFileTypes, originImagePostfix, pathRoutes } from "../constants";
import { apiGetEvents, apiDeleteEvent, deleteEventImage } from "../services";
import { useAuth } from "../Provider/AuthProvider";
import useLoadData from "../hooks/useLoadData";
import usePagination from "../hooks/usePagination";
import useEventsSort from "../hooks/useEventsSort";
import AdminEventsList from "../Components/AdminEventsList";
import PageLoader from "../Components/PageLoader";
import PageErrorMessage from "../Components/PageErrorMessage";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles(theme => ({
  paginationWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.typography.pxToRem(48),
    marginBottom: theme.typography.pxToRem(48),
  },
}));

const AdminEventsListPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [{ data, isLoading, error }, setEvents] = useLoadData([], apiGetEvents, false);
  const { events, Component: EventsSort } = useEventsSort(data);
  const { currentPage, maxPage, jumpToPage, getCurrentData } = usePagination(events, 15);
  const pageEvents = getCurrentData();
  const isSuperAdmin = user?.superAdmin;

  if (isLoading) return <PageLoader />;
  if (error) return <PageErrorMessage error={error} />;

  // eslint-disable-next-line react/prop-types
  const CreateEventButton = ({ style = {} }) => (
    <Button variant="contained" color="primary" style={style} onClick={() => history.push(pathRoutes.createEvent)}>
      {t("Create")} {t("Event")}
    </Button>
  );

  return (
    <Container maxWidth="md">
      <Box mb={3} display="flex" alignItems="center" justifyContent="space-between">
        <Box mr={1}>{isSuperAdmin && <CreateEventButton />}</Box>
        <EventsSort />
      </Box>

      <AdminEventsList
        isSuperAdmin={isSuperAdmin}
        events={pageEvents}
        editEvent={eventId => history.push(`${pathRoutes.createEvent}/${eventId}`)}
        deleteEvent={eventId => {
          const imageData = events.find(({ id }) => id === eventId)?.imageData;
          const imgType = imageFileTypes[imageData?.fileType];

          if (imageData && imgType) {
            deleteEventImage(`${eventId}${originImagePostfix}.${imgType}`).catch(console.error);
            deleteEventImage(`${eventId}.${imgType}`).catch(console.error);
          }

          apiDeleteEvent(eventId)
            .then(() => setEvents(events => events.filter(event => event.id !== eventId)))
            .catch(console.error);
        }}
      />

      <div
        className={classes.paginationWrapper}
        style={
          !isSuperAdmin
            ? {
                justifyContent: "center",
              }
            : {}
        }
      >
        {isSuperAdmin && (
          <Box mt={3.125} mb={3.125}>
            <CreateEventButton />
          </Box>
        )}

        {pageEvents.length > 0 && (
          <Pagination
            className={classes.pagination}
            count={maxPage}
            page={currentPage}
            onChange={(event, page) => jumpToPage(page)}
            showFirstButton
            showLastButton
            color="secondary"
          />
        )}
      </div>
    </Container>
  );
};

AdminEventsListPage.propTypes = {};

export default AdminEventsListPage;
