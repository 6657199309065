import PropTypes from "prop-types";

export const imageDataPropTypes = PropTypes.shape({
  originalUrl: PropTypes.string,
  url: PropTypes.string,
  crop: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  zoom: PropTypes.number,
  rotation: PropTypes.number,
});

export const eventPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  category: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  ageGroup: PropTypes.string,
  primaryFocus: PropTypes.string,
  currency: PropTypes.string,
  link: PropTypes.string,
  description: PropTypes.string,
  international: PropTypes.bool,
  subsidiary: PropTypes.bool,
  troubles: PropTypes.bool,
  excitement: PropTypes.bool,
  indoors: PropTypes.bool,
  outdoor: PropTypes.bool,
  moves: PropTypes.bool,
  audience: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  score: PropTypes.number,
  price: PropTypes.number,
  participants: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  recurring: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  strategicScores: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.string),
  alternativeStartDates: PropTypes.arrayOf(PropTypes.string),
  alternativeEndDates: PropTypes.arrayOf(PropTypes.string),
  imageData: imageDataPropTypes,
});
