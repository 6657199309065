import React, { useState, useEffect, useContext, createContext } from "react";
import PropTypes from "prop-types";
import { auth } from "../firebase";
import { superAdmins } from "../constants";

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signIn = (email, password) =>
    auth.signInWithEmailAndPassword(email, password).then(response => {
      response.user.superAdmin = superAdmins.includes(response.user?.uid);
      setUser(response.user);
      return response.user;
    });

  const signUp = (email, password) =>
    auth.createUserWithEmailAndPassword(email, password).then(response => {
      setUser(response.user);
      return response.user;
    });

  const signOut = () => auth.signOut().then(() => setUser(false));

  const sendPasswordResetEmail = email => auth.sendPasswordResetEmail(email).then(() => true);

  const confirmPasswordReset = (code, password) => auth.confirmPasswordReset(code, password).then(() => true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        user.superAdmin = superAdmins.includes(user.uid);
        setUser(user);
        // user
        //   .updateProfile({
        //     displayName: "Admin",
        //   })
        //   .then(r => console.log(r));
      } else {
        setUser(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return {
    user,
    signIn,
    signUp,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
}

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

ProvideAuth.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ProvideAuth;
