import React from "react";
import PropTypes from "prop-types";
import { pathRoutes } from "../constants";
import { apiGetEvent } from "../services";
import useLoadData from "../hooks/useLoadData";
import SingleEvent from "../Components/SingleEvent";
import PageLoader from "../Components/PageLoader";
import PageErrorMessage from "../Components/PageErrorMessage";
import Container from "@material-ui/core/Container";

const EventPage = ({ match = {} }) => {
  const eventId = match.params[pathRoutes.eventId];
  const [{ data: event, isLoading, error }] = useLoadData({}, apiGetEvent, !eventId, eventId);

  if (eventId && isLoading) return <PageLoader />;
  if (error) return <PageErrorMessage error={error} />;

  return (
    <Container maxWidth="lg">
      <SingleEvent event={event} />
    </Container>
  );
};

EventPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      [pathRoutes.eventId]: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default EventPage;
