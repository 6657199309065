/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

const TeenagerIcon = ({ width = 20, height = 20, fill = "#203934", className = "" }) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 20 20" className={className}>
    <path
      d="M7.7168 13.8489C7.95719 14.8874 8.88914 15.6641 9.99972 15.6641C11.1051 15.6641 12.0404 14.886 12.2822 13.849C11.5701 14.1114 10.8013 14.2549 9.99964 14.2549C9.19789 14.255 8.42898 14.1114 7.7168 13.8489Z"
      fill={fill}
    />
    <path
      d="M14.5878 12.5091H14.476C14.1764 12.7839 13.8516 13.0315 13.5058 13.2482C13.5123 13.2844 13.5159 13.3217 13.5155 13.36C13.505 14.2915 13.1345 15.1653 12.472 15.8203C11.8096 16.4753 10.9317 16.8361 10.0001 16.8361C8.06156 16.8361 6.48445 15.259 6.48445 13.3204C6.48445 13.2946 6.48488 13.2687 6.48547 13.2428C6.14285 13.0274 5.82105 12.7816 5.52398 12.509H5.41219C2.42793 12.5091 0 14.9328 0 17.9119V19.4118C0 19.7353 0.262266 19.9977 0.585859 19.9977L3.51559 19.9981V17.1876C3.51559 16.864 3.77793 16.6017 4.10152 16.6017C4.42512 16.6017 4.68746 16.864 4.68746 17.1876V19.9982L15.3125 19.9996V17.1876C15.3125 16.864 15.5749 16.6017 15.8985 16.6017C16.2221 16.6017 16.4844 16.864 16.4844 17.1876V19.9998L19.414 20.0002H19.414C19.5694 20.0002 19.7184 19.9384 19.8283 19.8285C19.9382 19.7187 20 19.5696 20 19.4142V17.9119C20 14.9328 17.5721 12.5091 14.5878 12.5091Z"
      fill={fill}
    />
    <path
      d="M10 4.14062C9.56922 4.14062 9.21875 4.49109 9.21875 4.92188H10.7812C10.7812 4.49109 10.4308 4.14062 10 4.14062Z"
      fill={fill}
    />
    <path
      d="M10.0005 2.96875C11.0775 2.96875 11.9537 3.84492 11.9537 4.92188H15.4396C15.1527 2.16645 12.8163 0 10.0005 0C7.18473 0 4.84836 2.16645 4.56152 4.92188H8.04742C8.04742 3.84492 8.92359 2.96875 10.0005 2.96875Z"
      fill={fill}
    />
    <path d="M15.3126 6.92211H15.4687V6.09375H13.9121C14.1881 6.58785 14.7182 6.92211 15.3126 6.92211Z" fill={fill} />
    <path
      d="M15.4455 8.09398H15.3129C14.615 8.09398 13.9475 7.83246 13.4332 7.35758C13.0579 7.01105 12.7895 6.5741 12.6498 6.09375H7.35035C7.21039 6.57375 6.94187 7.01035 6.56664 7.35652C6.05246 7.83094 5.3852 8.09219 4.68773 8.09219H4.55469C4.80941 10.8803 7.16098 13.083 10.0002 13.083C12.8388 13.083 15.1899 10.8812 15.4455 8.09398Z"
      fill={fill}
    />
    <path d="M4.68758 6.92035C5.28113 6.92035 5.81078 6.58687 6.08707 6.09375H4.53125V6.92035H4.68758Z" fill={fill} />
    <path
      d="M15.4682 6.09375H18.7104C19.034 6.09375 19.2963 5.83141 19.2963 5.50781C19.2963 5.18422 19.034 4.92188 18.7104 4.92188H15.4385C15.458 5.10977 15.4682 5.30039 15.4682 5.49328V6.09375Z"
      fill={fill}
    />
  </svg>
);

TeenagerIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default TeenagerIcon;
