/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

const FolkfestivalIcon = ({ width = 20, height = 20, fill = "#203934", className = "" }) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 20 20" className={className}>
    <path
      d="M19.6636 16.9987C18.6933 15.904 17.2362 15.3796 15.8155 15.5576L15.3036 13.2061C16.3701 12.7764 17.2626 11.992 17.7802 10.9508C18.2273 10.0516 18.3686 9.04125 18.2057 8.0672C17.7369 8.07373 17.2722 8.2667 16.8657 8.6374C15.5785 9.81178 13.8485 9.8115 12.5623 8.63682C11.7215 7.87008 10.6783 7.87008 9.83749 8.63682C9.52828 8.91921 9.19269 9.1298 8.84473 9.27647L8.87492 9.49457C9.06023 10.8364 9.81351 12.0352 10.9414 12.7836C11.7227 13.3025 12.6326 13.5715 13.5529 13.5715C13.7608 13.5715 13.9691 13.557 14.1768 13.5293L14.6835 15.8577C13.3428 16.4071 12.3593 17.5774 12.0634 19.0255C11.9956 19.3569 11.92 19.5952 12.1801 19.8403C12.3312 19.9835 12.5438 20.0315 12.7332 19.9798L19.5607 18.1602C19.7569 18.1076 19.9119 17.9571 19.9702 17.7631C20.0729 17.4208 19.888 17.2518 19.6636 16.9987Z"
      fill={fill}
    />
    <path
      d="M5.32029 15.859L5.84728 13.5282C6.05271 13.5555 6.25837 13.5696 6.46306 13.5696C7.42508 13.5696 8.36164 13.2676 9.14859 12.7291C8.3918 11.869 7.87504 10.8116 7.71508 9.65453L7.69606 9.51717C6.93872 9.51967 6.18095 9.22677 5.53478 8.63664C4.74471 7.91594 3.67768 7.84536 2.80999 8.63664C2.49429 8.92496 2.15152 9.1406 1.79563 9.28746C1.8493 9.85954 1.98711 10.4251 2.24832 10.9506C2.76527 11.9904 3.65616 12.7742 4.72076 13.2043L4.18827 15.5585C2.76624 15.3791 1.30653 15.9027 0.335673 16.9985C0.111339 17.2517 -0.073543 17.4206 0.0290736 17.763C0.0874326 17.957 0.242471 18.1075 0.438602 18.1601L7.26614 19.9797C7.45551 20.0313 7.66809 19.9833 7.81922 19.8401C8.0793 19.595 8.00367 19.3567 7.9359 19.0253C7.64039 17.5787 6.65872 16.4091 5.32029 15.859Z"
      fill={fill}
    />
    <path
      d="M9.99972 0C9.67601 0 9.41406 0.262065 9.41406 0.585915V1.75778C9.41406 2.08163 9.67601 2.3437 9.99972 2.3437C10.3234 2.3437 10.5854 2.08163 10.5854 1.75778V0.585954C10.5854 0.262065 10.3234 0 9.99972 0Z"
      fill={fill}
    />
    <path
      d="M8.07153 1.34351L6.90017 0.171636C6.67142 -0.057212 6.30075 -0.057212 6.07197 0.171636C5.84318 0.400484 5.84322 0.771307 6.07197 1.00019L7.24333 2.17206C7.47208 2.40091 7.84274 2.40091 8.07153 2.17206C8.30031 1.94318 8.30031 1.57239 8.07153 1.34351Z"
      fill={fill}
    />
    <path
      d="M13.9279 0.171636C13.6992 -0.057212 13.3285 -0.057212 13.0997 0.171636L11.9284 1.34351C11.6996 1.57235 11.6996 1.94314 11.9284 2.17206C12.1571 2.40095 12.5278 2.40095 12.7566 2.17206L13.9279 1.00019C14.1567 0.771307 14.1567 0.400523 13.9279 0.171636Z"
      fill={fill}
    />
    <path
      d="M16.1346 2.65732C16.0236 2.38439 15.7268 2.23503 15.4402 2.31229L8.65178 4.1319C8.36636 4.20857 8.18276 4.48553 8.22339 4.77792L8.67557 8.04819C8.80307 7.96987 8.9283 7.88034 9.04869 7.77053C10.3356 6.59522 12.0652 6.59522 13.3521 7.77053C14.1928 8.53726 15.2361 8.53726 16.0768 7.77053C16.6 7.29267 17.2254 7.01376 17.8728 6.93361L16.1346 2.65732Z"
      fill={fill}
    />
    <path
      d="M7.91996 3.20625L4.58928 2.31333C4.30729 2.23725 4.00647 2.38543 3.89495 2.65836L2.10242 7.0683C1.99375 7.33588 1.92383 7.61318 1.86621 7.89186C1.91781 7.85149 1.97094 7.81741 2.02121 7.77153C3.22976 6.66446 4.98272 6.54675 6.32458 7.77153C6.69892 8.11293 7.1136 8.29344 7.53247 8.33087L7.06352 4.93973C6.9647 4.22657 7.32583 3.55992 7.91996 3.20625Z"
      fill={fill}
    />
  </svg>
);

FolkfestivalIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default FolkfestivalIcon;
