import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PlusIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles({
  buttons: {
    display: "flex",
    flexWrap: "nowrap",
    borderRadius: 16,
    boxShadow: "0 0 6px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.1)",
  },
  button: {
    width: 48,
    height: 48,
    border: "1px solid #cccccc",
  },
  buttonLeft: {
    borderRadius: "16px 0 0 16px",
  },
  buttonRight: {
    borderRadius: "0 16px 16px 0",
  },
});

const ZoomButtons = ({ zoomIn, zoomOut, disabledIn = false, disabledOut = false }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttons}>
      <Button className={`${classes.button} ${classes.buttonLeft}`} onClick={() => zoomIn()} disabled={disabledIn}>
        <PlusIcon />
      </Button>
      <Button className={`${classes.button} ${classes.buttonRight}`} onClick={() => zoomOut()} disabled={disabledOut}>
        <MinusIcon />
      </Button>
    </div>
  );
};

ZoomButtons.propTypes = {
  zoomIn: PropTypes.func.isRequired,
  zoomOut: PropTypes.func.isRequired,
  disabledIn: PropTypes.bool,
  disabledOut: PropTypes.bool,
};

export default ZoomButtons;
