import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const FilterButton = withStyles({
  root: ({ active }) => ({
    position: "relative",
    paddingTop: 7,
    paddingBottom: 7,
    boxShadow: "none",
    border: "1px solid",
    backgroundColor: "#f2f2f2",
    borderColor: "#333333",
    borderRadius: 100,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#f2f2f2",
      borderColor: "#333333",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#f2f2f2",
      borderColor: "#333333",
    },
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      border: "1px solid",
      borderColor: "#333333",
      borderRadius: 100,
      opacity: active === "true" ? "1" : "0",
      transition: "all 0.1s ease",
    },
  }),
})(Button);

export default FilterButton;
