import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { pathRoutes } from "../constants";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(6.375),
    paddingRight: theme.spacing(9.875),
    paddingBottom: theme.spacing(2.1),
    paddingLeft: theme.spacing(6.375),
    backgroundColor: "#f7f9fb",
  },
  title: {
    textTransform: "uppercase",
  },
  navigationList: {
    padding: 0,
    listStyle: "none",
    display: "flex",
    flexDirection: "column",
    fontWeight: "500",
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
  activeLink: {
    color: theme.palette.secondary.main,
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container maxWidth={false} className={classes.root} component="footer">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" component="h6" paragraph className={classes.title}>
            <strong>
              {t("Tool")} {t("Navigation")}
            </strong>
          </Typography>
          <ul className={classes.navigationList}>
            <Link component={NavLink} to={pathRoutes.adminEvents} activeClassName={classes.activeLink}>
              {t("Events")} {t("List")}
            </Link>
            <Link
              component={NavLink}
              to={`${pathRoutes.events}/${pathRoutes.gantt}`}
              activeClassName={classes.activeLink}
            >
              {t("Gantt")} {t("Chart")}
            </Link>
            <Link
              component={NavLink}
              to={`${pathRoutes.events}/${pathRoutes.list}`}
              activeClassName={classes.activeLink}
            >
              {t("Card")} {t("List")}
            </Link>
            <Link
              component={NavLink}
              to={`${pathRoutes.events}/${pathRoutes.map}`}
              activeClassName={classes.activeLink}
            >
              {t("Map")} {t("View")}
            </Link>
          </ul>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" component="h6" paragraph className={classes.title}>
            <strong>
              {t("Quick")} {t("Links")}
            </strong>
          </Typography>
          <ul className={classes.navigationList}>
            <Link href="https://www.wonderfulcopenhagen.dk">{t("Wonderful Copenhagen")}</Link>
            <Link href="https://www.visitcopenhagen.com">
              {t("VisitCopenhagen")} ({t("official tourist site")})
            </Link>
          </ul>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" component="h6" paragraph className={classes.title}>
            <strong>{t("Contact")}</strong>
          </Typography>
          <ul className={classes.navigationList}>
            <Link href="#">{t("Report Bug")}</Link>
          </ul>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
