/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

const StarIcon = ({ width = 24, height = 24, fill = "#f2994a", className = "", number = "", numberFill = "white" }) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 18 18" className={className}>
    <path
      fill={fill}
      d="M9.00032 14.5198L13.1503 17.0298C13.9103 17.4898 14.8403 16.8098 14.6403 15.9498L13.5403 11.2298L17.2103 8.04977C17.8803 7.46977 17.5203 6.36977 16.6403 6.29977L11.8103 5.88977L9.92032 1.42977C9.58032 0.619766 8.42032 0.619766 8.08032 1.42977L6.19032 5.87977L1.36032 6.28977C0.480317 6.35977 0.120317 7.45977 0.790317 8.03977L4.46032 11.2198L3.36032 15.9398C3.16032 16.7998 4.09032 17.4798 4.85032 17.0198L9.00032 14.5198Z"
    />

    {typeof number === "number" && (
      <text
        width={width}
        height={height}
        x="0"
        y="0.5"
        dx={width / 3 + (parseInt(number, 10) >= 10 ? 0.5 : 1)}
        dy={height / 2}
        textAnchor="middle"
        style={{
          fontSize: 8,
          fontWeight: "bold",
        }}
        fill={numberFill}
      >
        {number}
      </text>
    )}
  </svg>
);

StarIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  numberFill: PropTypes.string,
  className: PropTypes.string,
};

export default StarIcon;
