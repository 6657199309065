import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    width: 25,
    height: 22,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const IconWrapper = ({ classes, children }) => <div className={classes.root}>{children}</div>;

IconWrapper.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default withStyles(styles)(IconWrapper);
